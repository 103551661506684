import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Person } from '../types/person.interface';
import { ENV } from '../../../../../config/config';
import { switchMap } from 'rxjs/operators';
import { WorkplaceShiftPersonRole } from '../../worker-scan-template/types/workplace-shift-person-role.interface';
import { WorkplaceShiftPeople } from '../types/workplace-shift-people.interface';
import { PersonAttendance } from '../types/person-attendance.interface';
import {Task} from '../../../../../types/task.interface';

@Injectable({ providedIn: 'root' })
export class WorkerLoggedInService {
    public currentActiveProcess: any;

    constructor(private http: HttpClient, @Inject(ENV) private env) {}
    getPersonWorkplaceShiftRolesByPersonIdAndWorkplaceIdAtTime(
        personId: string,
        workplaceId: string,
        shiftTime: string,
        fluxTask: Task
    ): Observable<WorkplaceShiftPersonRole[]> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<WorkplaceShiftPersonRole[]>(
            this.env.apiBaseUrl +
                `services/fluxprocessplanning/api/workplace-shift-person-roles/byPerson/${personId}/byWorkplace/${workplaceId}/atTime/${shiftTime}`
          , {headers}
        );
    }

    getPersonAttendanceEvent(personId: number, fluxTask: Task): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<PersonAttendance[]>(
            this.env.apiBaseUrl +
                'services/fluxprocess/api/person-attendance-events?personId.equals=' +
                personId +
                '&distinct=false&page=0&size=1&sort=eventTs%2Cdesc', {headers}
        );
    }
}
