<div class="container-fluid workflow-container">
  <!-- Header Section -->
  <div class="row mb-4">
    <div class="col-12 text-center">
      <h2 class="display-6">Prozessauswahl</h2>
      <p class="text-muted">Bitte wählen Sie einen Workflow aus</p>
    </div>
  </div>

  <!-- Search and View Toggle - Only shown when multiple processes -->
  <div class="row mb-4" *ngIf="processes.length > 1">
    <div class="col-12 col-md-8 mx-auto">
      <div class="search-container">
        <div class="input-group">
                            <span class="input-group-text border-end-0 bg-white">
                                <i class="fas fa-search text-muted"></i>
                            </span>
          <input
              type="text"
              class="form-control border-start-0 py-2"
              placeholder="Workflow suchen..."
              [(ngModel)]="searchTerm"
              (input)="filterProcesses()"
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Single Process View -->
  <div class="row justify-content-center" *ngIf="processes.length === 1">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="card workflow-card h-100" (click)="startProcess(processes[0])">
        <div class="card-body d-flex flex-column align-items-center text-center">
          <div class="workflow-icon mb-4">
            <i class="fas fa-cogs"></i>
          </div>
          <h3 class="card-title mb-3">{{getTitleOfProcess(processes[0])}}</h3>

          <p class="card-text" *ngIf="processes[0].description">
            {{processes[0].description}}
          </p>

          <div class="mt-4">
            <button class="btn btn-primary btn-lg" (click)="startProcess(processes[0])">
              Workflow starten
              <i class="fas fa-play ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Multiple Processes View -->
  <div class="row g-4 justify-content-center" *ngIf="processes.length > 1">
    <div class="col-12 col-md-6 col-lg-4" *ngFor="let process of filteredProcesses">
      <div class="card workflow-card h-100" (click)="startProcess(process)">
        <div class="card-body d-flex flex-column">
          <div class="workflow-header mb-3">
            <div class="workflow-icon">
              <i class="fas fa-cogs"></i>
            </div>
            <h3 class="card-title">{{getTitleOfProcess(process)}}</h3>
          </div>

          <p class="card-text flex-grow-1" *ngIf="process.description">
            {{process.description}}
          </p>

          <div class="workflow-meta mt-3">
                                <span class="badge bg-light text-dark me-2">
                                    ID: {{process.workflowId}}
                                </span>
            <span class="badge bg-primary" *ngIf="process.workflowTrigger">
                                    {{process.workflowTrigger}}
                                </span>
          </div>
        </div>
        <div class="card-footer bg-transparent border-0">
          <button class="btn btn-primary w-100" (click)="startProcess(process)">
            Workflow starten
            <i class="fas fa-play ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Empty State -->
  <div class="row mt-4" *ngIf="processes.length > 1 && !filteredProcesses.length">
    <div class="col-12 col-md-6 mx-auto text-center">
      <div class="empty-state">
        <i class="fas fa-search fa-3x mb-3"></i>
        <h3>Keine Workflows gefunden</h3>
        <p class="text-muted">
          Versuchen Sie es mit anderen Suchbegriffen
        </p>
      </div>
    </div>
  </div>
</div>