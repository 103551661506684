import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { ReplaySubject } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

export class WebSocketAPI extends OnDestroyMixin {
    webSocketEndPoint: string;
    topic: string;
    sendPath: string;

    stompClient: any;
    public messageQueue: ReplaySubject<any>;

    connected = false;

    constructor(messageQueue: ReplaySubject<any>) {
        super();
        this.messageQueue = messageQueue;
    }

    _connect(): void {
        console.log('Connect ' + this.webSocketEndPoint);
        if (!this.connected) {
            const ws = new SockJS(this.webSocketEndPoint);
            this.stompClient = Stomp.over(ws);
            // Uncomment to disable debug logging
            // this.stompClient.debug = f => f;
            const parentContext = this;
            parentContext.stompClient.connect(
                {},
                frame => {
                    parentContext.connected = true;
                    try {
                        parentContext.stompClient.subscribe(parentContext.topic, sdkEvent => {
                            parentContext.onMessageReceived(sdkEvent);
                        });
                    } catch (e) {
                        console.log('connect >>>> exception suppressed');
                    }
                },
                this.errorCallBack
            );
        }
    }

    _disconnect(): void {
        if (this.connected) {
            if (this.stompClient) {
                try {
                    this.stompClient.disconnect();
                } catch (e) {
                    console.log('disconnect >>>> exception suppressed');
                }
            }
            this.connected = false;
        }
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error): void {
        const parentContext = this;
        console.log('errorCallBack -> ' + error);
        setTimeout(() => {
            console.log('Try reconnecting ... ' + parentContext);
            parentContext?._connect();
        }, 5000);
    }

    /**
     * Send message to sever via web socket
     */
    _send(message): void {
        this.stompClient.send(this.sendPath, {}, JSON.stringify(message));
    }

    onMessageReceived(message): void {
        if (typeof message.body === 'object' && message.body !== null) {
            this.messageQueue.next(JSON.parse(message.body));
        } else {
            this.messageQueue.next(message.body);
        }
    }

    isSocketConnected(): boolean {
        return this.connected;
    }
}
