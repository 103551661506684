import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ENV } from '../config/config';
import { WorkplaceStatus } from '../types/workplace-status.interface';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WorkplaceService {
    constructor(private http: HttpClient) {}

    statusMap = Array();
    getCurrentWorkplaceStatus(workpalceId: number): Observable<any> {
        return this.http
            .get<any>(environment.apiBaseUrl + 'services/fluxprocess/api/workplace/' + workpalceId + '/getWorkplaceStatus')
            .pipe(switchMap(status => this.getStatusObject(status.workplaceStatusId)));
    }

    getStatusObject(statusId: number): Observable<any> {
        return this.http
            .get<WorkplaceStatus>(environment.apiBaseUrl + 'services/fluxmasterdata/api/workplace-statuses/' + statusId)
            .pipe(shareReplay(1));
    }


}
