import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { WorkerLoggedInService } from './service/worker-logged-in.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Operation } from '../show-running-orders/types/operation.interface';
import moment from 'moment/moment';
import { Person } from './types/person.interface';

import { Task } from '../../../../types/task.interface';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { CamundaService } from '../../../../service/camunda.service';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { getVariableValue } from '../../../../util/variable-service';
import { WorkplaceShiftPeople } from './types/workplace-shift-people.interface';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { map, switchMap, tap } from 'rxjs/operators';
import { PersonAttendance } from './types/person-attendance.interface';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'lib-flux-worker-logged-in-template',
    templateUrl: './worker-logged-in-template.component.html',
    styleUrls: ['./worker-logged-in-template.component.scss'],
    imports: [TranslatePipe, NgForOf],
    standalone: true
})
export class WorkerLoggedInTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    workplaceId: string;
    employeeId: string;
    otherPersons: string;
    personAttendances: PersonAttendance[] = [];
    workplaceMap = [];
    currentTask: Task;

    @Input() set task(task: Task) {
        this.currentTask = task;
        if (this.form) {
            console.log(this.form);
        }
    }

    @ViewChild('scanInput') scanInput: ElementRef;

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private workerLoggedInService: WorkerLoggedInService,
        private translate: TranslateService
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {}

    ngOnInit(): void {
        console.log(this.currentTask);
        this.workplaceId = this.currentTask.variables.workplace_id;
        this.employeeId = this.currentTask.variables.main_person;
        this.otherPersons = this.currentTask.variables.other_persons;
        console.log(getVariableValue(this.currentTask, 'workplace_id', null));
        super.onInit(false);

        if (this.employeeId) {
            this.workerLoggedInService
                .getPersonWorkplaceShiftRolesByPersonIdAndWorkplaceIdAtTime(
                    this.employeeId,
                    this.workplaceId,
                    moment().toISOString(),
                    this.currentTask
                )
                .pipe(
                    untilComponentDestroyed(this),
                    switchMap(peopleOnlineState => {
                        console.log(peopleOnlineState);
                        const peopleIds = this.otherPersons.split(',');
                        console.log(peopleIds);
                        const personAttendanceObservables = peopleIds.map(id =>
                            this.workerLoggedInService.getPersonAttendanceEvent(parseInt(id, 10), this.currentTask)
                        );

                        return forkJoin(personAttendanceObservables);
                    })
                )
                .subscribe(personAttendances => {
                    console.log(personAttendances);
                    personAttendances.forEach(value => {
                        this.personAttendances.push(value[0]);
                    });
                    console.log(this.personAttendances);
                });
        }
    }

    ngOnDestroy(): void {}

    nextTriggered(): void {
        this.finishTask('', null);
    }
}
