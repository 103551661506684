import { Routes } from '@angular/router';

import { AppAuthGuard } from '@flux/flux-keycloak-auth';
import { FluxFlowViewerComponent } from './components/viewer/viewer-overview/flux-flow-viewer.component';

export const routes: Routes = [
  {
    path: ':terminalId',
    component: FluxFlowViewerComponent,
    canActivate: [AppAuthGuard],
    children: []
  }
];