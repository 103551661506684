
<div class="row card-layout">
  <div class="col card-container">
    <div tabindex="0" class="card" id="basic_card">
          <div class="card-title">
            <div class="col-12 mx-auto">
              {{ currentTask.name }}
            </div>
        <div class="next-button-container" *ngIf="!isBundling">
          <button class="btn btn-primary" (click)="nextTriggered()">
            {{'LK.flow.orderOverview.skip' | translate}}
          </button>
        </div>
      </div>
      <div class="card-body d-flex justify-content-center align-items-center">
<div class="row w-100">
  <div class="col-10 mx-auto">
    <div *ngIf="currentTask.description" class="task-description">
      <span [innerHTML]="currentTask.description"></span>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-8  mx-auto alert alert-info" *ngIf="currentTask.infoText">
    <div class="row">
      <div class="col-1">
        <strong><i class="fa-solid fa-circle-info"></i></strong>
      </div>
      <div class="col-11">
        <span [innerHTML]="currentTask.infoText "></span>
      </div>
    </div>
  </div>
</div>
<div class="row">

  <div class="col-12">
    <table class="table">
      <thead>
      <tr>
        <th>{{'LK.general.id' | translate}}</th>
        <th>{{'LK.flow.orderOverview.operation' | translate}}</th>
        <th>{{'LK.general.options' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let operationStates of $operationsStates | async">
        <th>{{operationStates.operation.id}}</th>
        <th>{{operationStates.operation.number}}</th>
        <td>
          <ng-container *ngIf="hasRolePauseButtonPermission()">
            <button *ngIf="showPauseButton" class="btn btn-primary m-1" type="button"
                    (click)="signOutOperation(operationStates.operation)">{{'LK.flow.orderOverview.stopOperation' | translate}}
            </button>
          </ng-container>
          <ng-container *ngIf="hasRoleCloseButtonPermission()">
            <ng-container  *ngIf="showCloseButton">
              <button class="btn btn-danger" *ngIf="operationFinishedMap[operationStates.operation.id] == null"
                      type="button"
                      (click)="logoffOperation(operationStates.operation)">{{'LK.flow.orderOverview.completeOperation' | translate}}
              </button>
              <div class="btn-group" role="group" *ngIf="operationFinishedMap[operationStates.operation.id] == true">
                <button type="button" class="btn btn-primary"
                        (click)="logoffOperation(operationStates.operation)">{{'LK.general.logoffConfirmOperation' | translate}}</button>
                <button type="button" class="btn btn-danger" (click)="cancelLogoff(operationStates.operation)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</div>


      </div>
    </div>
  </div>
</div>
