import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    registerLocaleData(localeDe, 'de');
    translate.setDefaultLang('de');
    translate.use('de');
  }
}