<div class="background-task-container">
  <div class="task-content">
    <!-- Progress Animation -->
    <div class="progress-animation">
      <svg class="progress-ring" viewBox="0 0 100 100">
        <circle class="progress-ring-circle" cx="50" cy="50" r="45"/>
        <circle class="progress-ring-path" cx="50" cy="50" r="45"/>
      </svg>
      <div class="progress-icon">
        <i class="fas fa-cogs"></i>
      </div>
    </div>

    <!-- Task Information -->
    <div class="task-info">
      <h2>{{ currentTask.name || 'Hintergrundprozess läuft' }}</h2>

      <div class="info-box" *ngIf="currentTask.infoText">
        <div class="info-header">
          <i class="fas fa-info-circle"></i>
          <span>Prozess-Information</span>
        </div>
        <div class="info-content">
          <span [innerHTML]="currentTask.infoText"></span>
        </div>
      </div>

      <!-- Processing Steps -->
      <div class="processing-steps">
        <div class="step" [class.active]="true">
          <div class="step-icon">
            <i class="fas fa-sync-alt fa-spin"></i>
          </div>
          <div class="step-text">
            <span>Verarbeitung läuft</span>
            <small>Bitte warten Sie, während der Prozess ausgeführt wird</small>
          </div>
        </div>
      </div>
    </div>

    <!-- Task Actions -->
    <div class="task-actions">
      <button class="cancel-button" (click)="cancelProcess()">
        <i class="fas fa-times"></i>
        <span>Vorgang abbrechen</span>
      </button>
    </div>
  </div>
</div>

