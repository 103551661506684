import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { currentTaskReducers, TaskState } from './task-state.reducer';


export interface FluxBPMState {
    taskState: TaskState;
}

export function fluxBPMReducers(state: FluxBPMState | undefined, action: any): { taskState: any } {
    return {
        taskState: currentTaskReducers(state?.taskState, action)
    };
}

export const selectFluxBPM = createFeatureSelector<FluxBPMState>('flux-bpm');

export const selectCurrentSelectedTask = createSelector(
    selectFluxBPM,
    (fluxBPMState: FluxBPMState) => fluxBPMState.taskState?.currentActiveTask
);

export const selectPendingTaskState = createSelector(
    selectFluxBPM,
    (fluxBPMState: FluxBPMState) => fluxBPMState.taskState?.isFinished
);

export const selectCurrentProcessName = createSelector(
    selectFluxBPM,
    (fluxBPMState: FluxBPMState) => fluxBPMState.taskState?.currentProcessName
);

export const selectCurrentTerminalID = createSelector(
    selectFluxBPM,
    (fluxBPMState: FluxBPMState) => fluxBPMState.taskState?.terminalId
);
