
<div class="sensor-container" (click)="showDetails()">
  <ng-container *ngIf="initilazing">
    <div class="loading-overlay">
      <div class="overlay-loading-content-container" >
      <span class="overlay-loading-content" >Loading...</span>
      </div>
    </div>
  </ng-container>
  <div class="col-5">
    <ng-container *ngIf="!initilazing">
    <span class="sensorValue">
      {{sensorResult | number: "1.2-2"}}</span>
      <span class="sensorUnit">{{sensor.unit}}</span>
    </ng-container>
  </div>
  <div class="">
    <ngx-charts-line-chart
      [view]="view"
      [scheme]="colorScheme"
      [legend]="legend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [timeline]="timeline"
      [results]="realData"
      [autoScale]="true"
    >
    </ngx-charts-line-chart>

  </div>
</div>


