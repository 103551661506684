import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Person } from '../types/person.interface';
import { WorkplaceShiftPersonRole } from '../types/workplace-shift-person-role.interface';
import { ENV } from '../../../../../config/config';
import {Task} from '../../../../../types/task.interface';
import { environment } from '../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WorkerScanService {
    public currentActiveProcess: any;

    constructor(private http: HttpClient) {}

    getPeopleByCardIdIfExists(fluxTask: Task, cardId?: string): Observable<Person[]> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<Person[]>(
            environment.apiBaseUrl +
                'services/fluxmasterdata/api/people?page=0' +
                (cardId ? '&size=1&cardId.equals=' + encodeURI(cardId) : '&size=1000'), {headers}
        );
    }

    getPersonWorkplaceShiftRolesByPersonIdAndWorkplaceIdAtTime(
        personId: number,
        workplaceId: number,
        shiftTime: string,
        fluxTask: Task
    ): Observable<WorkplaceShiftPersonRole[]> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<WorkplaceShiftPersonRole[]>(
            environment.apiBaseUrl +
            `services/fluxprocessplanning/api/workplace-shift-person-roles/byPerson/${personId}/byWorkplace/${workplaceId}/atTime/${shiftTime}`
          , {headers}
        );
    }
}
