import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { ShowRunningOrdersService } from './service/show-running-orders.service';
import { Observable, Subject } from 'rxjs';
import { OperationOperationStatus } from './types/running-operation.interface';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Operation } from './types/operation.interface';

import { tap } from 'rxjs/operators';

import { Task } from '../../../../types/task.interface';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { CamundaService } from '../../../../service/camunda.service';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { getVariableValue } from '../../../../util/variable-service';
import { ButtonConfig } from '../../../../types/button-config.interface';
import { PermissionService } from '@flux/flux-keycloak-auth';
import { ToastrService } from 'ngx-toastr';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-running-operations-step-template',
    templateUrl: './show-running-orders-template.component.html',
    styleUrls: ['./show-running-orders-template.component.scss'],
    imports: [NgIf, TranslatePipe],
    standalone: true
})
export class ShowRunningOrdersTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    workplaceId: string;

    showPauseButton: string;

    showCloseButton: string;

    operationFinishedMap = [];

    $operationsStates: Observable<OperationOperationStatus[]>;

    @ViewChild('scanInput') scanInput: ElementRef;

    @Output() overrideConfigChange: EventEmitter<ButtonConfig> = new EventEmitter<ButtonConfig>();

    currentTask: Task;

    isBundling: boolean;

    readonly roleUICloseOrder = 'ROLE_pdaTerminal_workflows_ui_close_order'; // Role for close button
    readonly roleUIPauseOrder = 'ROLE_pdaTerminal_workflows_ui_pause_order'; // Role for pause button

    @Input() set task(task: Task) {
        this.currentTask = task;
    }

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private showRunningOrdersService: ShowRunningOrdersService,
        private translate: TranslateService,
        public permissionService: PermissionService,
        private toaster: ToastrService
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {}

    ngOnInit(): void {
        this.workplaceId = getVariableValue(this.currentTask, 'workplaceId', null);
        this.showPauseButton = getVariableValue(this.currentTask, 'showPauseButton', true);
        this.showCloseButton = getVariableValue(this.currentTask, 'showCloseButton', true);
        this.isBundling = this.parseStringToBoolean(getVariableValue(this.currentTask, 'isBundling', false));

        this.reloadOperations();

        super.onInit(false);
    }

    hasRolePauseButtonPermission(): boolean {
        return this.permissionService.hasSpecificPermission(this.roleUIPauseOrder);
    }
    hasRoleCloseButtonPermission(): boolean {
        return this.permissionService.hasSpecificPermission(this.roleUICloseOrder);
    }

    reloadOperations(): void {
        this.$operationsStates = this.showRunningOrdersService
            .getLoggedInOperations(this.workplaceId, this.currentTask)
            .pipe(
                tap(operations => {
                    if (!this.isBundling) {
                        if (operations.length === 0) {
                            this.finishTask('', null);
                        }
                    } else {
                        if (operations.length > 0) {
                            this.overrideConfigChange.emit({
                                showNextButton: true,
                                progressTrigger: this.progressTrigger,
                                showCancelButton: true,
                                nextButtonTranslationKey: 'LK.general.bundling.bundling',
                                disableNextButton: this.disableNextButton
                            });
                        } else {
                            this.overrideConfigChange.emit({
                                showNextButton: true,
                                progressTrigger: this.progressTrigger,
                                showCancelButton: true,
                                nextButtonTranslationKey: 'LK.general.next',
                                disableNextButton: this.disableNextButton
                            });
                        }
                    }
                })
            );
    }

    getOperationPostingMap(operation: Operation): any {
        return {
            id: operation.id,
            number: operation.number,
            workplaceId: this.workplaceId,
            centralAction: false
        };
    }

    signOutOperation(operation): void {
        this.showRunningOrdersService
            .stopOperation(this.getOperationPostingMap(operation), this.currentTask)
            .pipe(untilComponentDestroyed(this))
            .subscribe(
                _ => {
                    this.reloadOperations();
                    this.toaster.success('LK.general.success.logoutSuccess');
                },
                _ => {
                    this.toaster.error('LK.general.error.logoutFailed');
                }
            );
    }

    private parseStringToBoolean(value: string): boolean {
        return value.toLowerCase() === 'true';
    }

    logoffOperation(operation): void {
        if (this.operationFinishedMap[operation.id]) {
            this.showRunningOrdersService
                .finishOperation(this.getOperationPostingMap(operation), this.currentTask)
                .subscribe(
                    _ => {
                        this.operationFinishedMap[operation.id] = null;
                        this.reloadOperations();
                        this.toaster.success('LK.operationDetail.completeSuccess');
                    },
                    _ => {
                        this.toaster.error('LK.operationDetail.completeFailed');
                    }
                );
        } else {
            this.operationFinishedMap[operation.id] = true;
        }
    }

    cancelLogoff(operation): void {
        this.operationFinishedMap[operation.id] = null;
    }

    ngOnDestroy(): void {}

    nextTriggered(): void {
        this.finishTask('', null);
    }
}
