import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Task } from '../types/task.interface';
import { TaskResult } from '../types/task-result.interface';
import { ENV } from '../config/config';
import { SensorResponse } from '../types/sensor-response';
import {VariablesPosting} from '../types/variables-posting.interface';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CamundaService {
    public currentActiveTask: Task;
    public currentActiveProcess: any;
    private currentActiveProcessId: string;

    constructor(private http: HttpClient) {}

    getTerminal(terminalId: number): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl + 'services/fluxflow/api/worker-guidance-terminals/' + terminalId
        );
    }

    getProcessAssignedToTerminal(terminalId: number): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxflow/api/worker-guidance-terminal-workflow-assignments?terminalId.equals=' +
                terminalId
        );
    }


    cancelCurrentTask(processInstanceId: string): Observable<any> {
        return this.http.delete(environment.apiBaseUrl + 'services/fluxflow/api/bpm/abortUsertask/' + processInstanceId);
    }

    getWorkflowDefinitionValues(processKey: string): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl + 'services/fluxflow/engine-rest/process-definition/key/' + processKey + '/statistics'
        );
    }

    startProcess(process: string, workplaceId: number): Observable<any> {
        return this.http
            .get<any>(environment.apiBaseUrl + 'services/fluxflow/api/bpm/startProcess/' + process + '/' + workplaceId)
            .pipe(tap(processInstance => (this.currentActiveProcess = processInstance)));
    }

    getProcessInstance(workplaceId: number): Observable<any> {
        return this.http
            .get<any>(environment.apiBaseUrl + 'services/fluxflow/api/bpm/process/' + workplaceId)
            .pipe(tap(process => (this.currentActiveProcess = process)));
    }

    getTask(processInstanceId: string): Observable<Task> {
        return this.http
            .get<Task>(environment.apiBaseUrl + 'services/fluxflow/api/bpm/current/' + processInstanceId + '/')
            .pipe(tap(task => (this.currentActiveTask = task)));
    }

    getProcessTree(processInstanceId: string): Observable<Task> {
        return this.http
            .get<Task>(environment.apiBaseUrl + 'services/fluxflow/api/bpm/current/' + processInstanceId + '/')
            .pipe(tap(task => (this.currentActiveTask = task)));
    }

    hasActivityChanged(processInstanceId: string): Observable<Task> {
        return this.http
            .get<Task>(
                environment.apiBaseUrl +
                    'services/fluxflow/api/bpm/process/ping/' +
                    processInstanceId +
                    '/' +
                    this.currentActiveTask.taskDefinitionKey
            )
            .pipe(filter(task => !!task));
    }

    getLogsOfTask(processInstanceId: string): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxflow/api/production-logs?processId.equals=' +
                processInstanceId +
                '&sort=createdDate,desc'
        );
    }

    getVariableTask(processInstanceId: string): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxflow/engine-rest/history/variable-instance?processInstanceId=' +
                processInstanceId
        );
    }

    getLatestErrorsOfTask(processInstanceId: string): Observable<any> {
        return this.http.get<string[]>(
            environment.apiBaseUrl + 'services/fluxflow/api/bpm/current/' + processInstanceId + '/latestError'
        );
    }

    upcomingTasks(task: Task): Observable<string> {
        return this.http.get<string>(
            environment.apiBaseUrl +
                'services/fluxflow/api/bpm/upcoming/' +
                task.processInstanceId +
                '/' +
                task.taskDefinitionKey
        );
    }

    cancelCurrentProcess(processInstanceId: string): Observable<any> {
        return this.http.delete(
            environment.apiBaseUrl + 'services/fluxflow/engine-rest/process-instance/' + processInstanceId
        );
    }

    getHistoryOfGivenTask(task: Task): Observable<Task[]> {
        return this.http.get<Task[]>(
            environment.apiBaseUrl +
                'services/fluxflow/engine-rest/history/task?processInstanceId=' +
                task.processInstanceId +
                '&finished=true',
            {}
        );
    }

    completeTask(taskResult: TaskResult, processInstanceId: string): Observable<any> {
        return this.http.post<any>(
            environment.apiBaseUrl + 'services/fluxflow/api/bpm/completeUserTask/' + processInstanceId,
            taskResult
        );
    }

  storeVariables(variablesPosting: VariablesPosting, processInstanceId: string): Observable<any> {
    return this.http.post<any>(
      environment.apiBaseUrl + 'services/fluxflow/api/bpm/setVariables/' + processInstanceId,
      variablesPosting
    );
  }

    loadImageById(id: string): Observable<any> {
        return this.http.get<any>(environment.apiBaseUrl + 'services/fluxflow/api/uploads/' + id);
    }

    getDynamicChecklistElements(key: string, object: string, group: string): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxflow/api/flow-parameters?secondaryKey.equals=' +
                group +
                '&primaryKey.equals=' +
                key +
                '&object.equals=' +
                object
        );
    }

    getLatestSensorValue(sensorId: number): Observable<SensorResponse> {
        return this.http.get<SensorResponse>(
            environment.apiBaseUrl + 'services/fluxconnector/api/measure-data/measuring-point/' + sensorId + '/latest'
        );
    }

    getRecordingInstances(processInstanceId: string): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxproductionhistory/api/tagged-measure-data-periods?secondaryGroupKey.contains=' +
                processInstanceId
        );
    }

    loadSensorDataOfRecordedInstance(taggedMeasureDataPeriod: any): Observable<any> {
        return this.http.get<any>(
            environment.apiBaseUrl +
                'services/fluxproductionhistory/api/tagged-measure-data?taggedMeasureDataPeriodsId.equals=' +
                taggedMeasureDataPeriod.id
        );
    }
}
