import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskResult } from '../../../../types/task-result.interface';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { FinishedTaskAction } from '../../../../store/actions/task-state.action';
import { first } from 'rxjs/operators';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { ImageComponent } from '../../image-component/image.component';
import { TranslateDirective } from '@ngx-translate/core';
import { SensorComponent } from '../../sensor-component/sensor.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-default-step-template',
    templateUrl: './default-template.component.html',
    styleUrls: ['./default-template.component.scss'],
    imports: [ImageComponent, TranslateDirective, SensorComponent, NgIf],
    standalone: true
})
export class DefaultTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {
        if (this.form) {
            this.inputComponent.setValue('');
        }
    }

    get inputComponent(): UntypedFormControl {
        return this.form.get('inputComponent') as UntypedFormControl;
    }

    ngOnInit(): void {
        this.config.showNextButton = false;
        this.config.showCancelButton = true;
        const validators = [Validators.required];
        this.form = this.formBuilder.group({
            inputComponent: ['', validators]
        });
        super.onInit(false);
    }

    ngOnDestroy(): void {}

    getErrorKeys(): string[] {
        return Object.keys(this.inputComponent.errors);
    }
    nextTriggered(): void {
        this.finishTask('value', this.inputComponent.value);
    }
}
