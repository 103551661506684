import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../types/task.interface';
import { CamundaService } from '../../../service/camunda.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { UploadType } from './types/enums/upload-type';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgForOf, NgIf } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageViewerModalComponent } from './modal/image-viewer-modal.component';
import { ChartDetailModalComponent } from '../sensor-component/chart-detail-modal/chart-detail-modal.component';

@Component({
    selector: 'lib-flux-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    imports: [NgIf, PdfViewerModule, NgForOf],
    standalone: true
})
export class ImageComponent extends OnDestroyMixin implements OnInit, OnDestroy {


    currentImageIndex = 0;

    modalRef;

    constructor(
        private camundaService: CamundaService,
        public sanitizer: DomSanitizer,
        private modalService: NgbModal
    ) {
        super();
    }

    @Input() set task(task: Task) {
        this.imagesToDisplay = [];
        this.currentTask = task;

        this.currentTask.images.forEach(image => {
            this.camundaService
                .loadImageById(image.id)
                .pipe(untilComponentDestroyed(this))
                .subscribe(file => {
                    this.imagesToDisplay.push(file);
                    console.log(1111, this.imagesToDisplay);
                });
        });
    }
    currentTask: Task;



    imagesToDisplay = [];

    protected readonly UploadType = UploadType;

    ngOnInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    previousImage(): void {
        if (this.currentImageIndex > 0) {
            this.currentImageIndex--;
        }
    }

    nextImage(): void {
        if (this.currentImageIndex < this.imagesToDisplay.length - 1) {
            this.currentImageIndex++;
        }
    }

    setCurrentImage(index: number): void {
        this.currentImageIndex = index;
    }

    openFullscreen(image: any): void {
        this.modalRef = this.modalService.open(ImageViewerModalComponent,
            {
                size: 'xl',
                centered: true,
                windowClass: 'image-modal'
            });

        this.modalRef.componentInstance.selectedImage = image;
        this.modalRef.componentInstance.currentImageIndex = this.currentImageIndex;
        this.modalRef.componentInstance.imagesToDisplay = this.imagesToDisplay.length;
        this.modalRef.result.then(
            () => {
                this.modalRef = null;
            },
            reason => {}
        );

    }
}
