import { ApplicationConfig, ApplicationRef, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStore, StoreModule } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { provideToastr } from 'ngx-toastr';
import { FluxCoreModule } from '@flux/flux-core';
import { AppAuthGuard, FluxKeycloakAuthModule, PermissionService } from '@flux/flux-keycloak-auth';
import { fluxUnicornReducers, FluxUnicornStoreModule } from '@flux/flux-unicorn-store';
import { MultiTranslateHttpLoader } from './service/multi-translate-http-loader.service';
import { fluxBPMReducers } from './store/reducer/flux-bpm.reducers';
import { provideAnimations } from '@angular/platform-browser/animations';

export function createTranslateLoader(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    {
      prefix: '/services/fluxunicorn/api/translations/language/',
      suffix: '/map',
    },
  ]);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideToastr({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true
    }),
    importProvidersFrom(
        StoreModule.forRoot(fluxBPMReducers),
        EffectsModule.forRoot([]),
        FluxKeycloakAuthModule.forRoot(environment),
        FluxCoreModule.forRoot(environment),
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
          },
        }),
    ),
    provideStore({ 'flux-bpm': fluxBPMReducers}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false,
      }
    }),
    provideEffects([]),
    PermissionService,
    AppAuthGuard
  ]
};
