import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { Store } from '@ngrx/store';
import { CamundaService } from '../../../../service/camunda.service';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-background-task-step-template',
    templateUrl: './background-task-template.component.html',
    styleUrls: ['./background-task-template.component.scss'],
    imports: [NgIf],
    standalone: true
})
export class BackgroundTaskTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    public taskUpdated(): void {}

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    ngOnInit(): void {
        this.config.showNextButton = false;
        super.onInit(false);

    }

    ngOnDestroy(): void {}

    nextTriggered(): void {}


    cancelProcess(): void {
        if (this.cancelTrigger) {
            this.cancelTrigger.next(true);
        }
    }
}
