import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OperationOperationStatus } from '../types/running-operation.interface';
import { ENV } from '../../../../../config/config';
import {Task} from '../../../../../types/task.interface';

@Injectable({ providedIn: 'root' })
export class ShowRunningOrdersService {
    public currentActiveProcess: any;

    constructor(private http: HttpClient, @Inject(ENV) private env) {}

    getLoggedInOperations(workplaceId: string, fluxTask: Task): Observable<OperationOperationStatus[]> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<OperationOperationStatus[]>(
            this.env.apiBaseUrl + 'services/fluxordermanagement/api/workplace/' + workplaceId + '/runningOperations', {headers}
        );
    }

    stopOperation(operationPostingMap, fluxTask: Task): Observable<any> {

        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.post<any>(
            this.env.apiBaseUrl +
                'services/fluxprocess/api/operation/' +
                operationPostingMap.id +
                '/logoffFromWorkplace/' +
                operationPostingMap.workplaceId,
            operationPostingMap, {headers}
        );
    }

    finishOperation(operationPostingMap, fluxTask: Task): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.post<any>(
            this.env.apiBaseUrl + 'services/fluxprocess/api/operation/' + operationPostingMap.id + '/finishOperation',
            operationPostingMap, {headers}
        );
    }


}
