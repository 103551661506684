import { Component, Input } from '@angular/core';
import { Operation } from '../types/operation.interface';
import { TranslatePipe } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'lib-flux-operation-detail',
    templateUrl: './operation-detail.component.html',
    styleUrls: ['./operation-detail.component.scss'],
    imports: [TranslatePipe, DecimalPipe],
    standalone: true
})
export class OperationDetailComponent {
    @Input() operation: Operation;

    constructor() {}
}
