import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@Component({
    selector: 'lib-flux-chart-detail-modal',
    templateUrl: './chart-detail-modal.component.html',
    styleUrls: ['./chart-detail-modal.component.scss'],
    imports: [NgxChartsModule],
    standalone: true
})
export class ChartDetailModalComponent extends OnDestroyMixin implements OnInit {
    @Input() realData: BehaviorSubject<any>;
    @ViewChild('modalBody') modalBody!: ElementRef;

    view: any[] = [1000, 900];

    // options
    printData: any[] = [];
    legend: boolean = false;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = '';
    yAxisLabel: string = '';
    timeline: boolean = true;

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };

    constructor(public activeModal: NgbActiveModal) {
        super();
    }

    ngOnInit(): void {
        console.log(this.realData);
        this.realData.pipe(untilComponentDestroyed(this)).subscribe(val => {
            this.printData = val;

            this.view[0] = this.modalBody.nativeElement.offsetWidth - 20;

            this.printData = [...this.printData];
        });
    }

    close(): void {
        this.activeModal.dismiss();
    }
}
