import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../store/reducer/flux-bpm.reducers';
import { StartNewProcessAction } from '../../../store/actions/task-state.action';
import { CamundaService } from '../../../service/camunda.service';
import { Process } from '../../../types/process.interface';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Util } from '@flux/flux-core';
import { AbstractTemplateComponent } from '../abstract/abstract-template.component';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'lib-flux-waiting-view',
    templateUrl: './waiting-view.component.html',
    styleUrls: ['./waiting-view.component.scss'],
    imports: [NgForOf, NgIf, FormsModule],
    standalone: true
})
export class WaitingViewComponent extends OnDestroyMixin implements OnInit {
    processes: Process[] = [];
    filteredProcesses: Process[] = [];
    viewMode: 'grid' | 'list' = 'grid';
    searchTerm = '';

    constructor(
        private translation: TranslateService,
        private store: Store<FluxBPMState>,
        private camundaService: CamundaService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        const terminalId = this.route.params['value'].terminalId;
        this.camundaService
        .getProcessAssignedToTerminal(terminalId)
        .pipe(untilComponentDestroyed(this))
        .subscribe(res => {
            this.processes = res;
            this.filteredProcesses = res;
        });
    }

    filterProcesses(): void {
        if (!this.searchTerm) {
            this.filteredProcesses = this.processes;
            return;
        }

        const searchTermLower = this.searchTerm.toLowerCase();
        this.filteredProcesses = this.processes.filter(process =>
            this.getTitleOfProcess(process).toLowerCase().includes(searchTermLower) ||
            (process.description?.toLowerCase().includes(searchTermLower)) ||
            process.workflowId.toString().includes(searchTermLower) ||
            (process.terminalName?.toLowerCase().includes(searchTermLower))
        );
    }

    startProcess(process: Process): void {
        event?.stopPropagation();
        this.store.dispatch(new StartNewProcessAction(process.workflowName));
    }

    getTitleOfProcess(process: Process): string {
        if (Util.checkIfNull(process.name) || process.name === '') {
            return process.workflowName;
        }
        return process.name;
    }
}
