import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import merge from 'deepmerge';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        private resources: {
            prefix: string;
            suffix: string;
        }[]
    ) {}

    public getTranslation(lang: string): Observable<any> {
        const headers = new HttpHeaders();

        const requests = this.resources.map(resource => {
            const path = resource.prefix + lang + resource.suffix;
            console.log(this.http);
            return this.http.get(path, { headers }).pipe(
                // <-- We add the header into the request
                catchError(res => {
                    console.error('Something went wrong for the following translation file:', path);
                    console.error(res.message);
                    return of({});
                })
            );
        });

        return forkJoin(requests).pipe(map(response => merge.all(response)));
    }
}
