import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import { WorkplaceWorkplaceStatusRelation } from '../types/workplace-status.interface';
import { switchMap, tap } from 'rxjs/operators';
import { WorkplaceStatus } from '../../../../types/workplace-status.interface';
import { ENV } from '../../../../config/config';
import { Task } from '../../../../types/task.interface';
import { WorkplaceStatusOrderRelation } from '../types/workplace-status-order-relation.enum';
import { WorkplaceStatusPersonRelation } from '../types/workplace-status-person-relation.enum';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WorkplaceStatusService {
    public currentActiveProcess: any;

    workplaceStatuses$ = new BehaviorSubject<WorkplaceStatus[]>(undefined);
    private numberOfEmployeesLoggedIn: number;
    private numberOfOperationsLoggedIn: number;

    constructor(private http: HttpClient) {}

    getAvailableWorkplaceStatusOfWorkplaceAndSortBySortOrderASC(
        workplaceId,
        fluxTask: Task,
        isWorkerLogin: boolean,
        isWorkerLogout: boolean,
        isOperationLogin: boolean,
        isOperationLogout: boolean
    ): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.getCurrentOnlineStatusCount(workplaceId, fluxTask).pipe(
            tap(currentCount => {
                this.numberOfEmployeesLoggedIn = currentCount.numberOfWorkersSignedIn;
                this.numberOfOperationsLoggedIn = currentCount.numberOfOperationsSignedIn;

                if (isWorkerLogin) {
                    this.numberOfEmployeesLoggedIn = this.numberOfEmployeesLoggedIn + 1;
                } else if (isWorkerLogout) {
                    this.numberOfEmployeesLoggedIn = this.numberOfEmployeesLoggedIn - 1;
                }
                if (isOperationLogin) {
                    this.numberOfOperationsLoggedIn = this.numberOfOperationsLoggedIn + 1;
                } else if (isOperationLogout) {
                    this.numberOfOperationsLoggedIn = this.numberOfOperationsLoggedIn - 1;
                }
            }),
            switchMap(() => {
                return this.http
                    .get<any>(
                        environment.apiBaseUrl +
                            'services/fluxmasterdata/api/workplace-workplace-status-relations?workplaceId.equals=' +
                            workplaceId +
                            '&sort=sortOrder,asc',
                        { headers }
                    )
                    .pipe(
                        switchMap((wwsrs: WorkplaceWorkplaceStatusRelation[]) => {
                            const workplaceStatusIds = [...new Set(wwsrs.map(wwsr => wwsr.workplaceStatusId))];
                            return this.getWorkplaceStatusesByIds(workplaceStatusIds, fluxTask).pipe(
                                switchMap(workplaceStatuses => {
                                    const wwsrsFilteredByCheckIfStatusIsShown: WorkplaceWorkplaceStatusRelation[] = [];
                                    wwsrs.map(wwsr => {
                                        if (this.checkIfStatusIsShown(wwsr)) {
                                            wwsrsFilteredByCheckIfStatusIsShown.push({
                                                ...wwsr,
                                                fontColor: workplaceStatuses.find(
                                                    wws => wwsr.workplaceStatusId === wws.id
                                                ).fontColor,
                                                statusColor: workplaceStatuses.find(
                                                    wws => wwsr.workplaceStatusId === wws.id
                                                ).statusColor
                                            });
                                        }
                                    });
                                    wwsrsFilteredByCheckIfStatusIsShown.sort((a, b) => a.sortOrder - b.sortOrder);

                                    return of(wwsrsFilteredByCheckIfStatusIsShown);
                                })
                            );
                        })
                    );
            })
        );
    }

    getWorkplaceStatusesByIds(ids: number[], fluxTask: Task): Observable<WorkplaceStatus[]> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<WorkplaceStatus[]>(
            environment.apiBaseUrl +
                'services/fluxmasterdata/api/workplace-statuses?page=0&size=20&sort=id,desc&id.in=' +
                ids,
            { headers }
        );
    }

    getCurrentOnlineStatusCount(
        workplaceId: string,
        fluxTask: Task
    ): Observable<{ numberOfWorkersSignedIn: number; numberOfOperationsSignedIn: number }> {
        return forkJoin({
            numberOfWorkersSignedIn: this.checkOnlineStatusCount(workplaceId, 'PERSON', fluxTask),
            numberOfOperationsSignedIn: this.checkOnlineStatusCount(workplaceId, 'OPERATION', fluxTask)
        });
    }

    checkOnlineStatusCount(workplaceId: string, type: string, fluxTask: Task): Observable<number> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.get<number>(
            environment.apiBaseUrl +
                'services/fluxprocess/api/online-states/count?workplaceId.equals=' +
                workplaceId +
                '&type.equals=' +
                type,
            { headers }
        );
    }

    checkIfStatusIsShown(status: WorkplaceWorkplaceStatusRelation): boolean {
        if (
            status.orderStateRelation === WorkplaceStatusOrderRelation.NOT_RELATED &&
            status.personStateRelation === WorkplaceStatusPersonRelation.NOT_RELATED
        ) {
            return true;
        }
        if (status.orderStateRelation === WorkplaceStatusOrderRelation.ORDER_PRESENT) {
            if (this.numberOfOperationsLoggedIn <= 0) {
                return false;
            }
        } else if (status.orderStateRelation === WorkplaceStatusOrderRelation.ORDER_NOT_PRESENT) {
            if (this.numberOfOperationsLoggedIn > 0) {
                return false;
            }
        }
        if (status.personStateRelation === WorkplaceStatusPersonRelation.WORKER_PRESENT) {
            if (this.numberOfEmployeesLoggedIn <= 0) {
                return false;
            }
        } else if (status.personStateRelation === WorkplaceStatusPersonRelation.WORKER_NOT_PRESENT) {
            if (this.numberOfEmployeesLoggedIn > 0) {
                return false;
            }
        }
        return true;
    }

    setWorkplaceStatusEventByWorkplaceId(
        workplaceId: string,
        workplaceStatusEvent: any,
        fluxTask: Task
    ): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Source', 'Flow Screen Flow Task');
        headers.set('ProcessDefinition', fluxTask.processDefinitionId);
        headers.set('ProcessId', fluxTask.processInstanceId);
        headers.set('TaskId', fluxTask.activityId);
        return this.http.post<any>(
            environment.apiBaseUrl + 'services/fluxprocess/api/workplace/' + workplaceId + '/setWorkplaceStatus',
            workplaceStatusEvent,
            { headers }
        );
    }
}
