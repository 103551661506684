<div class="task-container" [class.has-media]="currentTask.sensors?.length || currentTask.images?.length">
  <div class="content-area">
    <!-- Header -->
    <div class="report-header">
      <div class="status-section">

        <div class="process-meta">
  <span class="meta-item">
    <i class="fas fa-clock text-muted"></i>
    {{ calculateDuration(currentTask.startDate) }}
  </span>
          <span class="meta-item">
    <i class="fas fa-play text-muted"></i>
            {{ currentTask.startDate | date: 'dd.MM.yyyy HH:mm' }}
  </span>
          <span class="meta-item">
    <i class="fas fa-stop text-muted"></i>
            {{ currentTime$ | async | date: 'dd.MM.yyyy HH:mm' }}
  </span>
        </div>
      </div>
      <div class="title-section">
        <div class="task-icon">
          <img src="assets/img/report_icon.png" alt="Report"/>
        </div>
        <h2>{{ currentTask.name }}</h2>
      </div>
    </div>

    <!-- Info Alert -->
    <div class="info-alert" *ngIf="currentTask.infoText">
      <i class="fas fa-info-circle"></i>
      <span>{{ currentTask.infoText }}</span>
    </div>

    <!-- Main Content -->
    <div class="report-content">
      <!-- Timeline Section -->
      <div class="content-section">
        <div class="section-header">
          <i class="fas fa-stream"></i>
          <h3>Prozessverlauf</h3>
        </div>
        <div class="timeline-container">
          @if (logs$ | async; as logs) {
            @for (log of logs.slice().reverse(); track log.createdDate; let i = $index) {
              <div class="timeline-item">
                <div class="timeline-marker" [ngClass]="getActivityStatusClass(log.logAction)">
                  <i class="fas" [class]="getActionIcon(log.logAction)"></i>
                </div>
                <div class="timeline-content">
                  <div class="content-header">
                    <div class="activity-info">
                      <span class="action-badge">{{ log.logAction }}</span>
                      <h4>{{ log.activity }}</h4>
                    </div>
                    <time>{{ toDateFormatted(log.createdDate) }}</time>
                  </div>
                  @if (log.logValue) {
                    <p class="activity-detail">{{ log.logValue }}</p>
                  }
                  @if (i < (logs.length - 1)) {
                    <div class="step-duration">
                      <i class="fas fa-clock"></i>
                      {{ getDurationBetweenSteps(log.createdDate, logs[logs.length - i - 2].createdDate) }}
                    </div>
                  }
                </div>
              </div>
            }
          }
        </div>
      </div>

      <!-- Variables Section -->
      <div class="content-section">
        <div class="section-header">
          <i class="fas fa-code-branch"></i>
          <h3>Prozessvariablen</h3>
        </div>
        <div class="table-container">
          @if (variables$ | async; as variables) {
            <table class="data-table">
              <thead>
              <tr>
                <th>Zeitpunkt</th>
                <th>Variable</th>
                <th>Wert</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              @for (variable of sortAndFilterVariables(variables); track variable.name) {
                <tr>
                  <td class="timestamp">{{ toDateFormatted(variable.createTime) }}</td>
                  <td class="variable-info">
                    <span class="variable-name">{{ variable.name }}</span>
                    <small class="variable-type">{{ variable.type }}</small>
                  </td>
                  <td class="variable-value">{{ formatVariableValue(variable.value, variable.type) }}</td>
                  <td class="text-end">
                      <span class="status-badge" [ngClass]="getVariableStatusClass(variable.state)">
                        {{ variable.state }}
                      </span>
                  </td>
                </tr>
              }
              </tbody>
            </table>
          } @else {
          <div class="empty-state">
            <i class="fas fa-info-circle"></i>
            <p>Keine Prozessvariablen vorhanden</p>
          </div>
        }
        </div>
      </div>
    </div>
  </div>

  <!-- Media Panel -->
  <div class="media-panel" *ngIf="currentTask.sensors?.length || currentTask.images?.length">
    <div class="content-section sensor-section" *ngIf="sensorData$ | async as sensorData">
      <div class="section-header">
        <i class="fas fa-chart-line"></i>
        <h3>Sensordaten</h3>
      </div>
      <div class="sensors-grid">
        @for (sensor of sensorData | keyvalue; track sensor.key) {
          <div class="sensor-card">
            <h4>{{ sensor.key }}</h4>
            <apx-chart
                [series]="sensor.value"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [tooltip]="chartOptions.tooltip">
            </apx-chart>
          </div>
        }
      </div>
    </div>

    <div class="content-section" *ngIf="currentTask.images?.length">
      <div class="section-header">
        <i class="fas fa-images"></i>
        <h3>Bilder</h3>
      </div>
      <lib-flux-image [task]="currentTask"></lib-flux-image>
    </div>
  </div>
</div>