<div class="error-container" [class.show-details]="showDetails">
  <div class="error-content">
    <!-- Compact Error View -->
    <div class="error-summary" [class.minimized]="showDetails">
      <div class="error-icon">
        <img src="assets/img/error_icon.png" alt="Error"/>
      </div>

      <div class="error-message">
        <h2>Ein Fehler ist aufgetreten</h2>
        <div class="alert alert-danger">
          <i class="fas fa-exclamation-circle"></i>
          {{ errorText || 'Es ist ein Fehler aufgetreten, bitte wenden Sie sich an ihren Admin.' | translate }}
        </div>
      </div>

      <div class="error-actions">
        <!--<button class="btn btn-secondary" (click)="retry()">
          <i class="fas fa-redo"></i>
          Erneut versuchen
        </button>-->
        <button class="btn btn-outline-primary" (click)="toggleDetails()">
          <i class="fas" [class.fa-chevron-down]="!showDetails" [class.fa-chevron-up]="showDetails"></i>
          {{ showDetails ? 'Details ausblenden' : 'Details anzeigen' }}
        </button>
      </div>
    </div>

    <!-- Full Width Report Section -->
    <div class="report-section" *ngIf="showDetails">
      <lib-flux-report-template
          [task]="currentTask"
          [compact]="false">
      </lib-flux-report-template>
    </div>
  </div>
</div>