<div class="footer-container">
  <button
      *ngIf="showCancel"
      class="btn btn-secondary"
      type="button"
      (click)="cancelTask()">
    <i class="fas fa-times me-2"></i>
    Abbrechen
  </button>
  <button
      *ngIf="showNext"
      class="btn btn-primary"
      [disabled]="disableNextButton"
      type="button"
      (click)="submitTask()">
    <ng-container *ngIf="!disableNextButton">
      {{ nextButtonTranslationKey | translate }}
      <i class="fas fa-arrow-right ms-2"></i>
    </ng-container>
    <ng-container *ngIf="disableNextButton">
      <i class="fas fa-spinner fa-spin me-2"></i>
      Bitte warten...
    </ng-container>
  </button>
</div>