<div class="row card-layout">
  <div class="col card-container">
    <div tabindex="0" class="card" id="basic_card">
      <div class="task-icon"><img src="assets/img/form_icon.png"/></div>
      <div class="card-title">
        <div class="col-12">
          {{ currentTask.name }}
        </div>
      </div>
      <div class="card-body d-flex justify-content-center align-items-center card">
        <div class="col-6 mx-auto">
        <form (ngSubmit)="save()" [formGroup]="formGroup" novalidate>
          <flux-flux-embedded-fieldset
            [dataObj]="dataObject"
            [onFormSubmitted$]="onFormSubmitted$"
            [embeddedForm]="formGroup"
            [form]="layoutForm"
          ></flux-flux-embedded-fieldset>

          <div class="row" style="margin-top: 23px">
            <div class="col-12" >
              <button class="btn btn-primary btn-lg" type="submit">
                <span >{{ 'LK.general.save' | translate }}</span>
              </button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 mx-auto" *ngIf="currentTask.sensors?.length>0 || currentTask.images?.length>0">
    <lib-flux-image [task]="currentTask"></lib-flux-image>
    <lib-flux-sensor *ngFor="let sensor of currentTask.sensors" [sensor]="sensor"></lib-flux-sensor>
  </div>
</div>

