<div class="task-container">
  <!-- Main Content -->
  <div class="content-area">
    <div class="checklist-header">
      <div class="header-icon">
        <img src="assets/img/checklist_icon.png" alt="Checklist Icon"/>
      </div>
      <h2 class="header-title">{{ currentTask.name }}</h2>
    </div>

    <div class="checklist-content">
      <!-- Description & Info -->
      <div class="content-section" *ngIf="currentTask.description || currentTask.infoText">
        <div class="task-description" *ngIf="currentTask.description">
          <span [innerHTML]="currentTask.description"></span>
        </div>

        <div class="info-alert" *ngIf="currentTask.infoText">
          <i class="fas fa-info-circle info-icon"></i>
          <div class="info-content">
            <span [innerHTML]="currentTask.infoText"></span>
          </div>
        </div>
      </div>

      <!-- Checklist Items -->
      <form [formGroup]="form" class="checklist-form">
        <div class="checklist-items">
          <div class="checklist-item"
               *ngFor="let checklistItem of currentTask.checklistItems"
               [class.disabled]="checkIfDisabled(checklistItem)"
               [class.optional]="checklistItem.optional">
            <label class="custom-checkbox">
              <input
                  type="checkbox"
                  [id]="checklistItem.id"
                  [formControl]="checkBoxControls[checklistItem.id]"
                  (change)="changeCheckboxValue(checklistItem)"
                  [disabled]="checkIfDisabled(checklistItem)"
              />
              <span class="checkbox-mark">
                <i class="fas fa-check"></i>
              </span>
              <span class="checkbox-label">
                {{ checklistItem.value | translate }}
                <span class="optional-tag" *ngIf="checklistItem.optional">(Optional)</span>
              </span>
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Side Panel -->
  <div class="media-panel" *ngIf="hasSideContent()">
    <div class="images-section" *ngIf="currentTask.images?.length">
      <lib-flux-image [task]="currentTask"></lib-flux-image>
    </div>
    <div class="sensors-section" *ngIf="currentTask.sensors?.length">
      <lib-flux-sensor *ngFor="let sensor of currentTask.sensors"
                       [sensor]="sensor">
      </lib-flux-sensor>
    </div>
  </div>
</div>