import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { TaskResult } from '../../../../types/task-result.interface';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { FinishedTaskAction } from '../../../../store/actions/task-state.action';
import { first } from 'rxjs/operators';
import { InputVariable } from '../../../../types/input-variable';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { CheckListItem } from '../../../../types/checklist.interface';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { getVariableValue } from '../../../../util/variable-service';
import { TranslatePipe } from '@ngx-translate/core';
import { SensorComponent } from '../../sensor-component/sensor.component';
import { ImageComponent } from '../../image-component/image.component';
import { NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-dyn-checklist-step-template',
    templateUrl: './dyn-checklist-template.component.html',
    styleUrls: ['./dyn-checklist-template.component.scss'],
  imports: [ReactiveFormsModule, TranslatePipe, SensorComponent, ImageComponent, NgForOf, NgIf],
    standalone: true
})
export class DynChecklistTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    checkBoxValues = [];

    checkBoxCheckedStatus = [];

    checkBoxControls = [];

    allowSkipCheck = false;

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private formBuilder: UntypedFormBuilder
    ) {
        super(camundaService, store);
        this.form = this.formBuilder.group({});
    }

    public taskUpdated(): void {}

    ngOnInit(): void {
        this.allowSkipCheck = Boolean(JSON.parse(getVariableValue(this.currentTask, 'allowSkipCheck', null)));
        this.camundaService
            .getDynamicChecklistElements(
                this.currentTask.inputMappingVariables.filter(
                    (inputVariable: InputVariable) => inputVariable.variableNameInObject === 'key'
                )[0].sourceVariable,
                this.currentTask.inputMappingVariables.filter(
                    (inputVariable: InputVariable) => inputVariable.variableNameInObject === 'object'
                )[0].sourceVariable,
                this.currentTask.inputMappingVariables.filter(
                    (inputVariable: InputVariable) => inputVariable.variableNameInObject === 'group'
                )[0].sourceVariable
            )
            .pipe(untilComponentDestroyed(this))
            .subscribe(res => {
                this.checkBoxValues = res;
                res.forEach(el => {
                    const validators = [];
                    validators.push(Validators.required);
                    const control: UntypedFormControl = new UntypedFormControl(el.id, validators);
                    this.checkBoxControls[el.id] = control;
                    control.setValue(false);
                    this.form.addControl(el.id + '', control);
                    this.checkBoxCheckedStatus[el.id] = false;
                });
                this.progressTrigger.next(this.checkIfCompleteIsAllowed());
            });
        super.onInit(false);
    }

    changeCheckboxValue(item: CheckListItem): void {
        this.progressTrigger.next(this.checkIfCompleteIsAllowed());
    }

    checkIfCompleteIsAllowed(): boolean {
        if (this.allowSkipCheck) {
            return false;
        }
        return Object.keys(this.checkBoxControls).some(key => {
            if (this.checkBoxControls[key].value) {
                return false;
            } else if (!this.checkBoxControls[key].value) {
                return true;
            } else {
                return false;
            }
        });
    }

    ngOnDestroy(): void {}

    nextTriggered(): void {
        this.finishTask('', null);
    }
}
