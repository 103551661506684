import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { Subject } from 'rxjs';
import { Form } from '@flux/flux-core';
import { CurrentFormService, FluxDynamicFormsModule } from '@flux/flux-dynamic-forms';
import { getVariableValue } from '../../../../util/variable-service';
import { first } from 'rxjs/operators';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TranslatePipe } from '@ngx-translate/core';
import { ImageComponent } from '../../image-component/image.component';
import { SensorComponent } from '../../sensor-component/sensor.component';

@Component({
    selector: 'lib-flux-form-step-template',
    templateUrl: './form-template.component.html',
    styleUrls: ['./form-template.component.scss'],
    imports: [FluxDynamicFormsModule, ReactiveFormsModule, TranslatePipe, ImageComponent, SensorComponent],
    standalone: true
})
export class FormTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    currentTask: Task;

    formGroup: UntypedFormGroup;

    public onFormSubmitted$: Subject<any> = new Subject();

    controlsArray = [];

    layoutForm: Form;

    dataObject = {} as any;

    prefix: string;

    newObjectCondition = true;

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private formBuilder: UntypedFormBuilder,
        private currentForm: CurrentFormService
    ) {
        super(camundaService, store);
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({});
        const formKey = getVariableValue(this.currentTask, 'formkey', null);
        this.prefix = getVariableValue(this.currentTask, 'prefix', '');
        this.currentForm
            .initByFormKey(formKey)
            .pipe(untilComponentDestroyed(this))
            .subscribe((form: Form) => {
                this.layoutForm = form;
            });
        this.config.showNextButton = false;
        this.config.showCancelButton = true;
        this.config.progressTrigger.next(false);

        super.onInit(true);
    }

    save(): void {
        if (this.formGroup.valid) {
            const userMap = new Map(
                Object.entries(this.dataObject).map(([key, value]) => [`${this.prefix}${key}`, String(value)])
            );
            const objectFromMap = Object.fromEntries(userMap);
            console.log({ values: objectFromMap });
            this.camundaService
                .storeVariables({ values: objectFromMap }, this.currentTask.processInstanceId)
                .pipe(first())
                .subscribe(() => {
                    this.finishTask('', null);
                });
        } else {
            Object.keys(this.formGroup.controls).forEach(controlName => {
                this.formGroup.controls[controlName].markAsTouched();
            });
        }
    }
    ngOnDestroy(): void {}

    nextTriggered(): void {}

    taskUpdated(): void {}
}
