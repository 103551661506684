import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, formatDate, NgIf } from '@angular/common';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    FluxBPMState,
    selectCurrentSelectedTask,
    selectCurrentTerminalID
} from '../../../store/reducer/flux-bpm.reducers';
import { CamundaService } from '../../../service/camunda.service';
import { ProcessFinishedAction } from '../../../store/actions/task-state.action';
import { concatMap, filter, map, startWith, switchMap } from 'rxjs/operators';
import { Util } from '@flux/flux-core';
import { WorkplaceService } from '../../../service/workplace.service';
import { Terminal } from '../../../types/terminal.interface';
import { SocketConfig } from '../../../types/socket.config';
import { SocketStateService } from '../../../websocket/socket-state.service';
import { ENV } from '../../../config/config';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { WebSocketAPI } from '../../../websocket/websocket-api';
import { environment } from '../../../../environments/environment';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from './modal/help-modal.component';

@Component({
    selector: 'lib-flux-flow-viewer-header',
    templateUrl: './flux-viewer-header.component.html',
    styleUrls: ['./flux-viewer-header.component.scss'],
    imports: [AsyncPipe, NgIf, NgbTooltip],
    standalone: true
})
export class FluxViewerHeaderComponent extends OnDestroyMixin implements OnInit, OnDestroy {
    currentDate: string;

    timerInstance: any;

    currentActiveProcess: any;

    terminal$: Observable<any>;

    currentMachineStatus$: Observable<any>;

    hasActiveProcess = false;

    webSocketConnection$: Observable<WebSocketAPI>;

    constructor(
        private store: Store<FluxBPMState>,
        private camundaService: CamundaService,
        private workplaceService: WorkplaceService,
        private socketStateService: SocketStateService,
        private modalService: NgbModal
    ) {
        super();
    }

    ngOnInit(): void {
        const parentContext = this;
        clockRun();
        setInterval(clockRun, 500);

        function clockRun(): void {
            parentContext.currentDate = formatDate(Date.now(), 'dd.MM.yyyy HH:mm:ss', 'de-DE');
        }
        this.store
            .select(selectCurrentSelectedTask)
            .pipe(untilComponentDestroyed(this))
            .subscribe(task => {
                if (Util.checkIfNull(task)) {
                    this.hasActiveProcess = false;
                } else {
                    this.hasActiveProcess = true;
                }
            });
        this.currentActiveProcess = this.camundaService.currentActiveProcess;

        this.terminal$ = this.store.select(selectCurrentTerminalID).pipe(
            filter(terminalID => !!terminalID),
            switchMap(terminalID => this.camundaService.getTerminal(terminalID))
        );

        const currentWorkplaceStatus$ = this.terminal$.pipe(
            concatMap((terminal: Terminal) => this.workplaceService.getCurrentWorkplaceStatus(terminal.workplaceId))
        );

        const socketConfig = {
            id: 'WorkplaceStatus',
            endpoint: environment.processSocketUrl,
            topic: '/topic/process/events',
            sendPath: ''
        } as any as SocketConfig;

        this.webSocketConnection$ = this.socketStateService.initialize(socketConfig);
        const messageQueue = this.webSocketConnection$.pipe(
            untilComponentDestroyed(this),
            map((webSocket: WebSocketAPI) => {
                return webSocket.messageQueue.pipe(
                    untilComponentDestroyed(this),
                    startWith('{}'),
                    filter(response => !!response),
                    map((response: any) => JSON.parse(response)),
                    switchMap((response: any) => {
                        if (Util.checkIfNull(response['workplaceStatusId'])) {
                            return of(response);
                        }
                        return this.workplaceService.getStatusObject(response.workplaceStatusId);
                    })
                );
            })
        );

        this.currentMachineStatus$ = combineLatest([currentWorkplaceStatus$, messageQueue]).pipe(
            concatMap(([state1, state2]) => {
                if (!Util.checkIfNull(state2['name'])) {
                    return of(state2);
                } else {
                    return of(state1);
                }
            })
        );
    }

    openFeedbackModal(): void {
        this.modalService.open(HelpModalComponent, {
            size: 'lg',
            backdrop: 'static'
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.timerInstance);
    }

    cancel(): void {
        this.camundaService
            .cancelCurrentProcess(this.camundaService.currentActiveProcess.processInstanceId)
            .pipe(untilComponentDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new ProcessFinishedAction());
            });
    }
}
