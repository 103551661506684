import { Component, OnDestroy, OnInit } from '@angular/core';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { CheckListItem } from '../../../../types/checklist.interface';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { ImageComponent } from '../../image-component/image.component';
import { SensorComponent } from '../../sensor-component/sensor.component';
import { NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-checklist-step-template',
    templateUrl: './checklist-template.component.html',
    styleUrls: ['./checklist-template.component.scss'],
    imports: [ReactiveFormsModule, TranslatePipe, ImageComponent, SensorComponent, NgIf, NgForOf],
    standalone: true
})
export class ChecklistTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    checkBoxValues = [];

    checkBoxControls = [];

    checkBoxOptional = [];

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private formBuilder: UntypedFormBuilder
    ) {
        super(camundaService, store);
        this.form = this.formBuilder.group({});
    }

    public taskUpdated(): void {
        this.checkBoxValues = [];
        this.currentTask.checklistItems = this.currentTask.checklistItems.sort((a, b) => a.order - b.order);
        this.currentTask.checklistItems.forEach(item => {
            console.log(item.id);
            this.checkBoxValues[item.id] = false;
            this.checkBoxOptional[item.id] = item.optional;
            const validators = [];
            if (!item.optional) {
                validators.push(Validators.required);
                this.progressTrigger.next(true);
            }
            const control: UntypedFormControl = new UntypedFormControl(item.id, validators);
            control.setValue(false);
            this.checkBoxControls[item.id] = control;
            this.form.addControl(item.id + '', control);
        });
        this.progressTrigger.next(this.checkIfCompleteIsAllowed());
    }

    ngOnInit(): void {
        super.onInit(true);
    }

    ngOnDestroy(): void {}

    checkIfDisabled(item: CheckListItem): boolean {
        if (item.dependsOn && item.dependsOn > 0) {
            const isDisabled = !this.checkBoxValues[item.dependsOn];
            if (isDisabled) {
                this.checkBoxValues[item.id] = false;
            }
            return isDisabled;
        }
        return false;
    }

    changeCheckboxValue(item: CheckListItem): void {
        this.progressTrigger.next(this.checkIfCompleteIsAllowed());
    }

    checkIfCompleteIsAllowed(): boolean {
        return Object.keys(this.checkBoxControls).some(key => {
            if (this.checkBoxControls[key].value) {
                return false;
            } else if (!this.checkBoxControls[key].value) {
                return true;
            } else {
                return false;
            }
        });
    }

    nextTriggered(): void {
        this.finishTask('', null);
    }

    hasSideContent(): boolean {
        return (this.currentTask?.images?.length > 0 || this.currentTask?.sensors?.length > 0);
    }
}
