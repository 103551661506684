import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../types/task.interface';
import { FluxBPMState, selectCurrentSelectedTask, selectPendingTaskState } from '../../store/reducer/flux-bpm.reducers';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ButtonConfig } from '../../types/button-config.interface';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { LoadingTemplateComponent } from './step-templates/loading-template/loading-template.component';
import { StopwatchTemplateComponent } from './step-templates/stopwatch-template/stopwatch-template.component';
import { ReportTemplateComponent } from './report/report-template.component';
import { ScanTemplateComponent } from './step-templates/scan-template/scan-template.component';
import { ErrorTemplateComponent } from './step-templates/error-template/error-template.component';
import { UserInputTemplateComponent } from './step-templates/user-input-template/user-input-template.component';
import { ChecklistTemplateComponent } from './step-templates/checklist-template/checklist-template.component';
import { DynChecklistTemplateComponent } from './step-templates/dyn-checklist-template/dyn-checklist-template.component';
import { BackgroundTaskTemplateComponent } from './step-templates/background-task-template/background-task-template.component';
import { ShowRunningOrdersTemplateComponent } from './step-templates/show-running-orders/show-running-orders-template.component';
import { WorkerScanTemplateComponent } from './step-templates/worker-scan-template/worker-scan-template.component';
import { WorkerLoggedInTemplateComponent } from './step-templates/worker-logged-template/worker-logged-in-template.component';
import { WorkplaceStatusTemplateComponent } from './machine-status-template/workplace-status-template.component';
import { OperationListComponent } from './step-templates/order_sign_in/operation-list/operation-list.component';
import { FormTemplateComponent } from './step-templates/form-template/form-template.component';
import { DefaultTemplateComponent } from './step-templates/default-template/default-template.component';
import { StepFooterTemplateComponent } from './step-templates/step-footer/step-footer-template.component';

@Component({
    selector: 'lib-flux-current-step',
    templateUrl: './current-step.component.html',
    styleUrls: ['./current-step.component.scss'],
    imports: [
        NgIf,
        AsyncPipe,
        LoadingTemplateComponent,
        StopwatchTemplateComponent,
        NgSwitch,
        NgSwitchCase,
        ReportTemplateComponent,
        ScanTemplateComponent,
        ErrorTemplateComponent,
        UserInputTemplateComponent,
        ChecklistTemplateComponent,
        DynChecklistTemplateComponent,
        BackgroundTaskTemplateComponent,
        ShowRunningOrdersTemplateComponent,
        WorkerScanTemplateComponent,
        WorkerLoggedInTemplateComponent,
        WorkplaceStatusTemplateComponent,
        OperationListComponent,
        FormTemplateComponent,
        DefaultTemplateComponent,
        StepFooterTemplateComponent
    ],
    standalone: true
})
export class CurrentStepComponent extends OnDestroyMixin implements OnInit, OnDestroy {
    currentTask$: Observable<Task>;

    isFooterHidden = false;

    taskPending$: Observable<boolean>;

    public nextTrigger = new Subject();

    public cancelTrigger = new Subject();

    public configChangeListener: Subject<ButtonConfig> = new Subject<ButtonConfig>();

    constructor(private store: Store<FluxBPMState>) {
        super();
    }

    ngOnInit(): void {
        this.currentTask$ = this.store.select(selectCurrentSelectedTask).pipe(
            untilComponentDestroyed(this),
            filter(task => !!task)
        );
        this.taskPending$ = this.store.select(selectPendingTaskState).pipe(untilComponentDestroyed(this));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    componentInitializes(val: ButtonConfig): void {
        this.currentTask$.pipe(untilComponentDestroyed(this)).subscribe(currentTask => {
            switch (currentTask.taskType) {
                case 'SHOW_RUNNING_ORDERS':
                    val.showNextButton = false;
                    break;
                case 'QUESTION':
                    val.showNextButton = false;
                    val.showCancelButton = false;
                    break;
                default:
                    val.showNextButton = true;
                    val.showCancelButton = true;
                    break;
            }
        });
        this.configChangeListener.next(val);
    }

    triggerCancel(val: any): void {
        this.cancelTrigger.next(val);
    }

    triggerNext(val: boolean): void {
        this.nextTrigger.next(val);
    }

    overrideConfigChangeListener(event: any): void {
        this.configChangeListener.next(event);
    }

    toggleFooter(show: boolean): void {
        this.isFooterHidden = !show;
    }
}
