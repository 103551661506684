import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';

import { debounceTime, first, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { WorkerScanService } from './service/worker-scan.service';
import { Util } from '@flux/flux-core';
import { of } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Person } from './types/person.interface';
import { WorkplaceShiftPersonRole } from './types/workplace-shift-person-role.interface';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { CamundaService } from '../../../../service/camunda.service';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { getVariableValue } from '../../../../util/variable-service';
import { stringToBoolean } from '../../../../util/boolean-converter';
import { ToastrService } from 'ngx-toastr';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-worker-scan-step-template',
    templateUrl: './worker-scan-template.component.html',
    styleUrls: ['./worker-scan-template.component.scss'],
    imports: [ReactiveFormsModule, NgIf],
    standalone: true
})
export class WorkerScanTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;

    foundWorker: Person;

    @ViewChild('employeeCardId', { static: false }) employeeCardIdInput: ElementRef;

    readonly teamLeaderRoleName = 'TEAM_LEADER';

    isTeamLeadCheckActive = true;

    workplaceId: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private translation: TranslateService,
        private workerScanService: WorkerScanService,
        private toaster: ToastrService
    ) {
        super(camundaService, store);
    }

    get scanResult(): UntypedFormControl {
        return this.form.get('scanResult') as UntypedFormControl;
    }

    focusOutFunction(): void {
        setTimeout(() => this.employeeCardIdInput.nativeElement.focus());
    }

    ngOnInit(): void {
        const validators = [Validators.required];
        this.currentTask.patternMappingVariables.forEach(pattern => {
            validators.push(this.regexValidator(new RegExp(pattern.pattern), { pattern: pattern.failureMessage }));
        });

        this.form = this.formBuilder.group({
            scanResult: ['', validators]
        });
        setTimeout(() => this.employeeCardIdInput.nativeElement.focus());

        this.form.valueChanges.subscribe((result: any) => {
            this.progressTrigger.next(!this.form.valid);
        });
        this.workplaceId = getVariableValue(this.currentTask, 'workplaceId', null);
        this.isTeamLeadCheckActive = stringToBoolean(
            getVariableValue(this.currentTask, 'is_team_lead_check_active', null)
        );
        this.scanResult?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            if (this.scanResult.value?.trim().length > 0) {
                this.getWorkerByCardNumber();
            }
        });
        super.onInit(true);
    }

    ngOnDestroy(): void {}

    regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: UntypedFormControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }

    getErrorKeys(): string[] {
        return Object.keys(this.scanResult.errors);
    }

    public taskUpdated(): void {
        if (this.form) {
            this.scanResult.setValue('');
        }
    }

    getWorkerByCardNumber(): void {
        this.workerScanService
            .getPeopleByCardIdIfExists(this.currentTask, this.scanResult.value)
            .pipe(
                first(),
                switchMap(persons => {
                    if (Util.checkIfNull(persons) || persons.length === 0) {
                        this.toaster.error(this.translation.instant('LK.flow.noMatchingPersonFound'));
                        this.progressTrigger.next(true);
                        return of([]);
                    } else {
                        console.log(this.foundWorker);
                        this.foundWorker = persons[0];
                        this.progressTrigger.next(false);
                        return this.workerScanService.getPersonWorkplaceShiftRolesByPersonIdAndWorkplaceIdAtTime(
                            this.foundWorker.id,
                            this.workplaceId,
                            moment().toISOString(),
                            this.currentTask
                        );
                    }
                }),
                untilComponentDestroyed(this)
            )
            .subscribe((workplaceRoles: WorkplaceShiftPersonRole[]) => {
                if (workplaceRoles.length > 0) {
                    const hasTeamLeaderRole = workplaceRoles.some(role => role.shiftRole === this.teamLeaderRoleName);

                    if (this.isTeamLeadCheckActive) {
                        if (!hasTeamLeaderRole) {
                            this.toaster.error(this.translation.instant('LK.flow.noTeamLeader'));

                            this.progressTrigger.next(true);
                        }
                    }
                } else {
                    this.progressTrigger.next(false);
                }
                this.scanResult.setValue('', { emitEvent: false });
            });
    }

    getEmployeeName(): string {
        if (this.foundWorker) {
            return !this.foundWorker?.surname || this.foundWorker?.surname?.trim().length === 0
                ? this.foundWorker?.firstname
                : this.foundWorker?.firstname + ' ' + this.foundWorker?.surname;
        } else {
            return '';
        }
    }

    nextTriggered(): void {
        console.log(this.foundWorker);
        if (!Util.checkIfNull(this.foundWorker)) {
            this.toaster.success('LK.flow.personFound');
            this.finishTask('WorkerScan', JSON.stringify(this.foundWorker));
        }
    }
}
