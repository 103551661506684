<div class="task-container" [class.has-media]="currentTask.sensors?.length || currentTask.images?.length">
  <div class="content-area">
    <form [formGroup]="form" class="scan-form">
      <!-- Header -->
      <div class="task-header">
        <div class="header-content">
          <div class="task-icon">
            <img src="assets/img/scan_icon.png" alt="Scan"/>
          </div>
          <h2>{{ currentTask.name }}</h2>
        </div>
      </div>

      <!-- Main Content -->
      <div class="task-content">
        <!-- Description -->
        <div class="content-section" *ngIf="currentTask.description">
          <div class="task-description">
            <span [innerHTML]="currentTask.description"></span>
          </div>
        </div>

        <!-- Info Box -->
        <div class="info-box" *ngIf="currentTask.infoText">
          <div class="info-content">
            <i class="fas fa-info-circle"></i>
            <span [innerHTML]="currentTask.infoText"></span>
          </div>
        </div>

        <!-- Scan Input -->
        <div class="scan-input-section">
          <div class="input-container">
            <div class="input-wrapper">
              <i class="fas fa-barcode input-icon"></i>
              <input
                  class="scan-input"
                  placeholder="Bitte Barcode scannen oder eingeben ..."
                  autofocus
                  formControlName="scanResult"
                  type="text"
              />
            </div>

            <!-- Error Messages -->
            <div class="error-messages" *ngIf="scanResult && scanResult.errors && (scanResult.dirty || scanResult.touched)">
              <ng-container *ngFor="let errorKey of getErrorKeys()">
                <div class="error-message" *ngIf="scanResult?.errors[errorKey]">
                  <i class="fas fa-exclamation-circle"></i>
                  <span [translate]="'LK.general.validation.text.' + (errorKey === 'pattern' ?
                    scanResult?.errors[errorKey] : errorKey)"
                        [translateParams]="errorKey !== 'pattern' ?
                    { value: scanResult?.errors[errorKey][errorKey] } : null">
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Media Panel -->
  <div class="media-panel" *ngIf="currentTask.sensors?.length || currentTask.images?.length">
    <div class="images-section" *ngIf="currentTask.images?.length">
      <lib-flux-image [task]="currentTask"></lib-flux-image>
    </div>
    <div class="sensors-section" *ngIf="currentTask.sensors?.length">
      <lib-flux-sensor
          *ngFor="let sensor of currentTask.sensors"
          [sensor]="sensor">
      </lib-flux-sensor>
    </div>
  </div>
</div>