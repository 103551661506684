<div class="task-container">
  <div class="scan-card">
    <!-- Header -->
    <div class="card-header">
      <h2>{{ currentTask.name }}</h2>
    </div>

    <!-- Content -->
    <div class="card-content">
      <form [formGroup]="form">
        <!-- Description -->
        <div class="task-description" *ngIf="currentTask.description">
          <span [innerHTML]="currentTask.description"></span>
        </div>

        <!-- Info Box -->
        <div class="info-box" *ngIf="currentTask.infoText">
          <div class="info-icon">
            <img src="assets/img/scan_icon.png" alt="Scan Info"/>
          </div>
          <div class="info-text">
            <span [innerHTML]="currentTask.infoText"></span>
          </div>
        </div>

        <!-- Scan Area -->
        <div class="scan-area">
          <div class="scan-animation">
            <i class="fas fa-id-card card-icon"></i>
            <div class="scan-line"></div>
          </div>

          <div class="scan-input-container">
            <input
                (keydown.enter)="nextTriggered()"
                autofocus
                type="text"
                formControlName="scanResult"
                class="scan-input"
                (focusout)="focusOutFunction()"
                id="employeeCardId"
                #employeeCardId
                placeholder="Karte scannen..."
            />
          </div>

          <!-- Employee Name Display -->
          <div class="employee-info" [class.active]="getEmployeeName()">
            <i class="fas fa-user"></i>
            <span class="employee-name">{{ getEmployeeName() || 'Karte bereithalten' }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>