<div class="image-viewer" *ngIf="imagesToDisplay.length > 0">
  <!-- Image Navigation -->
  <div class="image-nav" *ngIf="imagesToDisplay.length > 1">
    <button class="nav-button" (click)="previousImage()" [disabled]="currentImageIndex === 0">
      <i class="fas fa-chevron-left"></i>
    </button>
    <span class="image-counter">{{currentImageIndex + 1}} / {{imagesToDisplay.length}}</span>
    <button class="nav-button" (click)="nextImage()" [disabled]="currentImageIndex === imagesToDisplay.length - 1">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <!-- Image Display -->
  <div class="image-container" [class.multiple-images]="imagesToDisplay.length > 1">
    <ng-container *ngFor="let image of imagesToDisplay; let i = index">
      <div class="image-wrapper"
           [class.active]="i === currentImageIndex"
           (click)="openFullscreen(image)">

        <!-- Standard Image -->
        <ng-container *ngIf="image.uploadType === UploadType.IMAGE">
          <img [src]="sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image.file)"
               [alt]="'Bild ' + (i + 1)"
               class="display-image">
        </ng-container>

        <!-- URL Image -->
        <ng-container *ngIf="image.uploadType === UploadType.IMAGE_URL">
          <img [src]="image.url"
               [alt]="'Bild ' + (i + 1)"
               class="display-image">
        </ng-container>

        <!-- PDF -->
        <ng-container *ngIf="image.uploadType === UploadType.PDF">
          <div class="pdf-container">
            <pdf-viewer [src]="image.url"
                        [render-text]="true"
                        [original-size]="false"
                        [zoom]="0.8"
                        class="pdf-viewer">
            </pdf-viewer>
            <div class="pdf-overlay">
              <i class="fas fa-file-pdf"></i>
              <span>PDF anzeigen</span>
            </div>
          </div>
        </ng-container>

        <!-- Zoom Indicator -->
        <div class="zoom-indicator">
          <i class="fas fa-search-plus"></i>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Thumbnail Navigation for multiple images -->
  <div class="thumbnail-nav" *ngIf="imagesToDisplay.length > 1">
    <div class="thumbnail-container">
      <div *ngFor="let image of imagesToDisplay; let i = index"
           class="thumbnail"
           [class.active]="i === currentImageIndex"
           (click)="setCurrentImage(i)">
        <ng-container *ngIf="image.uploadType !== UploadType.PDF">
          <img [src]="image.uploadType === UploadType.IMAGE ?
                              sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image.file) :
                              image.url"
               [alt]="'Thumbnail ' + (i + 1)">
        </ng-container>
        <div *ngIf="image.uploadType === UploadType.PDF" class="pdf-thumbnail">
          <i class="fas fa-file-pdf"></i>
        </div>
      </div>
    </div>
  </div>
</div>
