<div class="task-container" [class.has-media]="currentTask.sensors?.length || currentTask.images?.length">
  <!-- Main Content Area -->
  <div class="content-area">
    <div class="task-card">
      <!-- Header -->
      <div class="card-header">
        <div class="header-content">
          <h2>{{ currentTask.name }}</h2>
          <div class="task-icon">
            <img src="assets/img/default_bpm_step_icon.png" alt="Task Icon"/>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="card-body">
        <!-- Description -->
        <div class="description-section" *ngIf="currentTask.description">
          <div class="task-description">
            <span [innerHTML]="currentTask.description"></span>
          </div>
        </div>

        <!-- Info Box -->
        <div class="info-box" *ngIf="currentTask.infoText">
          <i class="fas fa-info-circle info-icon"></i>
          <div class="info-content">
            <span [innerHTML]="currentTask.infoText"></span>
          </div>
        </div>

        <!-- Input Section -->
        <div class="input-section" *ngIf="inputComponent">
          <div class="input-container">
            <input
                class="form-control task-input"
                autofocus
                formControlName="scanResult"
                type="text"
                [placeholder]="'Eingabe...'"/>

            <!-- Validation Messages -->
            <div class="validation-feedback"
                 *ngIf="inputComponent?.errors &&
                       (inputComponent.dirty || inputComponent.touched)">
              <ng-container *ngFor="let errorKey of getErrorKeys()">
                <div class="error-message"
                     *ngIf="inputComponent?.errors[errorKey]">
                  <i class="fas fa-exclamation-circle"></i>
                  <span [translate]="'LK.general.validation.text.' +
                    (errorKey === 'pattern' ?
                      inputComponent?.errors[errorKey] : errorKey)"
                        [translateParams]="errorKey !== 'pattern' ?
                      { value: inputComponent?.errors[errorKey][errorKey] } : null">
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Media Panel -->
  <div class="media-panel" *ngIf="currentTask.sensors?.length || currentTask.images?.length">
    <!-- Images Section -->
    <div class="images-section" *ngIf="currentTask.images?.length">
      <lib-flux-image [task]="currentTask"></lib-flux-image>
    </div>

    <!-- Sensors Section -->
    <div class="sensors-grid" *ngIf="currentTask.sensors?.length">
      <lib-flux-sensor
          *ngFor="let sensor of currentTask.sensors"
          [sensor]="sensor">
      </lib-flux-sensor>
    </div>
  </div>
</div>