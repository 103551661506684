import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription, takeWhile } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Moment } from 'moment/moment';

@Injectable({ providedIn: 'root' })
export class StopwatchService {
    time$: Observable<Date>;
    private subscription: Subscription = new Subscription();
    private accumulatedTime = 0;
    private startTime: Date = new Date();
    private running!: boolean;

    private countdownTime = 4 * 60 * 1000; // 4 minutes in milliseconds
    countdownSubject = new BehaviorSubject<string>('00:30:000');
    countdownOver$ = new BehaviorSubject(false);

    constructor() {}

    startCountdown(): void {
        const startTime = Date.now();
        const timer$ = interval(1) // emits every 1 milliseconds
            .pipe(
                map(elapsed => this.countdownTime - (Date.now() - startTime)),
                takeWhile(timeLeft => timeLeft >= 0),
                tap(timeLeft => {
                    if (timeLeft <= 10) {
                        this.countdownOver$.next(true);
                    } else {
                        this.countdownOver$.next(false);
                    }
                }),
                map(timeLeft => this.formatTime(timeLeft))
            );

        timer$.subscribe(time => {
            this.countdownSubject.next(time);
        });
    }

    private formatTime(timeLeft: number): string {
        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        const milliseconds = timeLeft % 1000;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliseconds
            .toString()
            .padStart(3, '0')}`;
    }

    start(): void {
        if (!this.running) {
            this.running = true;
            const timer$ = interval(1000).pipe(
                startWith(0),
                map(() => {
                    return new Date(Date.now() - this.startTime.getTime() + this.accumulatedTime);
                })
            );
            this.subscription = timer$.subscribe(time => {
                this.time$ = timer$;
            });
        }
    }

    stop(): void {
        if (this.running) {
            this.running = false;
            this.accumulatedTime = Date.now() - this.startTime.getTime() + this.accumulatedTime;
            this.subscription.unsubscribe();
        }

        this.reset();
    }

    calculateTimeDifference(start: Moment, end: Moment): string {
        const differenceInMilliseconds = end.diff(start);
        const addLeadingZero = number => (number < 10 ? `0${number}` : number.toString());

        const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
        const milliseconds = differenceInMilliseconds % 1000;

        const formattedMinutes = addLeadingZero(minutes);
        const formattedSeconds = addLeadingZero(seconds);
        const formattedMilliseconds = addLeadingZero(milliseconds);

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    private reset(): void {
        this.accumulatedTime = 0;
        this.startTime = new Date();
        this.time$ = new Observable(observer => {
            observer.next(new Date(0));
        });
    }
}
