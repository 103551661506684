import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';

@Component({
    selector: 'lib-flux-loading-step-template',
    templateUrl: './loading-template.component.html',
    styleUrls: ['./loading-template.component.scss'],
    standalone: true
})
export class LoadingTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    private timeoutHandle;
    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {
        // this.restartTimer();
    }

    ngOnInit(): void {
        this.config.showNextButton = false;
        this.config.showCancelButton = true;
        super.onInit(true);
    }

    restartTimer(): void {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(() => {
            window.location.reload();
        }, 3000);
    }

    ngOnDestroy(): void {
        if (this.timeoutHandle) {
            // clearTimeout(this.timeoutHandle);
        }
    }

    nextTriggered(): void {
        this.finishTask('', null);
    }

    cancelProcess(): void {
        if (this.cancelTrigger) {
            this.cancelTrigger.next(true);
        }
    }
}
