import { Util } from '@flux/flux-core';
import { Task } from '../types/task.interface';

export const getVariableValue = (task: Task, variableName: string, defaultValue: any) => {
    const searchedVariable = task.inputMappingVariables.find(
        variable => variable.variableNameInObject === variableName
    );
    if (Util.checkIfNull(searchedVariable)) {
        return defaultValue;
    } else {
        return searchedVariable.sourceVariable;
    }
};
