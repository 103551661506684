import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { first } from 'rxjs/operators';
import { WorkplaceStatusService } from './service/workplace-status.service';
import { Observable, Subscription } from 'rxjs';
import { WorkplaceStatusOrderRelation } from './types/workplace-status-order-relation.enum';
import { WorkplaceStatusPersonRelation } from './types/workplace-status-person-relation.enum';
import { WorkplaceWorkplaceStatusRelation } from './types/workplace-status.interface';
import { Task } from '../../../types/task.interface';
import { AbstractTemplateComponent } from '../abstract/abstract-template.component';
import { getVariableValue } from '../../../util/variable-service';
import { CamundaService } from '../../../service/camunda.service';
import { FluxBPMState } from '../../../store/reducer/flux-bpm.reducers';
import { Util } from '@flux/flux-core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'lib-flux-workplace-status-step-template',
    templateUrl: './workplace-status-template.component.html',
    styleUrls: ['./workplace-status-template.component.scss'],
    imports: [NgIf, TranslatePipe, AsyncPipe, NgForOf],
    standalone: true
})
export class WorkplaceStatusTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    workplaceId: string;

    isWorkerLogin: boolean;

    isWorkerLogout: boolean;

    isOperationLogin: boolean;

    isOperationLogout: boolean;

    states$: Observable<WorkplaceWorkplaceStatusRelation[]>;

    eventTs: Date;

    disableButtons = false;

    addSkipButton = false;

    currentTask: Task;

    @Input() set task(task: Task) {
        this.currentTask = task;
    }

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private workplaceStatusService: WorkplaceStatusService
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {}

    ngOnInit(): void {
        this.config.showNextButton = false;
        this.workplaceId = getVariableValue(this.currentTask, 'workplaceId', null);
        this.isWorkerLogin = Boolean(JSON.parse(getVariableValue(this.currentTask, 'workerLoginMode', null)));
        this.isWorkerLogout = Boolean(JSON.parse(getVariableValue(this.currentTask, 'workerLogoutMode', null)));
        this.isOperationLogin = Boolean(JSON.parse(getVariableValue(this.currentTask, 'operationLoginMode', null)));
        this.isOperationLogout = Boolean(JSON.parse(getVariableValue(this.currentTask, 'operationLogoutMode', null)));
        this.addSkipButton = Boolean(JSON.parse(getVariableValue(this.currentTask, 'addSkipButton', false)));
        this.states$ = this.workplaceStatusService.getAvailableWorkplaceStatusOfWorkplaceAndSortBySortOrderASC(
            1,
            this.currentTask,
            this.isWorkerLogin,
            this.isWorkerLogout,
            this.isOperationLogin,
            this.isOperationLogout
        );

        super.onInit(true);
    }

    ngOnDestroy(): void {}

    submitTask(): void {
        this.finishTask('', null);
    }

    nextTriggered(): void {}

    getEventTs(): Date {
        if (this.currentTask.variables.eventTs) {
            return this.currentTask.variables.eventTs;
        } else {
            return new Date();
        }
    }

    getOperator(): string {
        if (this.currentTask.variables.personId) {
            return this.currentTask.variables.personId;
        } else {
            return null;
        }
    }

    setWorkplaceStatus(workplaceStatusId: number): void {
        if (this.disableButtons === false) {
            this.workplaceStatusService
                .setWorkplaceStatusEventByWorkplaceId(
                    this.workplaceId,
                    this.createPosting(workplaceStatusId),
                    this.currentTask
                )
                .pipe(untilComponentDestroyed(this))
                .subscribe(
                    _ => {
                        setTimeout(() => {
                            console.log('Erfolgreich');
                            this.submitTask();
                        }, 500);
                    },
                    _ => {
                        console.log('Failed');
                        this.disableButtons = false;
                    }
                );
        }
    }

    private createPosting(workplaceStatusId: number): any {
        const eventTs = this.getEventTs();
        if (Util.checkIfNull(eventTs)) {
            return {
                workplaceStatusId,
                workplaceId: this.workplaceId,
                personId: this.getOperator()
            };
        } else {
            return {
                eventTs: this.getEventTs(),
                workplaceStatusId,
                workplaceId: this.workplaceId,
                personId: this.getOperator()
            };
        }
    }
}
