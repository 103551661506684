import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { OperationService } from '../service/operation.service';
import { Observable } from 'rxjs';
import { getVariableValue } from '../../../../../util/variable-service';
import { Task } from '../../../../../types/task.interface';
import { AbstractTemplateComponent } from '../../../abstract/abstract-template.component';
import { CamundaService } from '../../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../../store/reducer/flux-bpm.reducers';
import { AsyncPipe, DecimalPipe, NgForOf, NgIf } from '@angular/common';
import { OperationDetailComponent } from '../operation-detail/operation-detail.component';

@Component({
    selector: 'lib-flux-operation-sign-in',
    templateUrl: './operation-list.component.html',
    styleUrls: ['./operation-list.component.scss'],
    imports: [AsyncPipe, NgForOf, DecimalPipe, OperationDetailComponent, TranslatePipe, NgIf],
    standalone: true
})
export class OperationListComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    workplaceId: string;

    operations$: Observable<any>;

    currentTask: Task;

    selectedOperation: any;

    @Input() set task(task: Task) {
        this.currentTask = task;
        this.workplaceId = getVariableValue(this.currentTask, 'workplaceId', null);
        this.operations$ = this.operationService.getOperationsToLogin(this.workplaceId);
    }

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private operationService: OperationService,
        private translate: TranslateService
    ) {
        super(camundaService, store);
    }

    ngOnInit(): void {
        this.config.showNextButton = false;
        super.onInit(true);
    }

    onOperationSelect(operation: any): void {
        this.selectedOperation = operation;
        console.log(this.selectedOperation);
    }

    logonOperation(): void {
        const operationPostingMap = {} as any;
        operationPostingMap.id = this.selectedOperation.id;
        operationPostingMap.workplaceId = this.workplaceId;
        this.operationService
            .logonOperation(operationPostingMap)
            .pipe(untilComponentDestroyed(this))
            .subscribe(event => {
                this.finishTask('', this.selectedOperation.id);
            });
    }

    nextTriggered(): void {}

    taskUpdated(): void {}
}
