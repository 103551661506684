<div class="viewer-header">
  <ng-container *ngIf="this.terminal$ | async as terminal">
    <div class="container-fluid">
      <div class="row align-items-center h-100">
        <!-- Terminal Info -->
        <div class="col-md-3">
          <div class="d-flex align-items-center">
            <div class="terminal-name me-3">
              {{ terminal.name }}
            </div>
            <div class="machine-status">
              <i [style.color]="(currentMachineStatus$ | async)?.statusColor"
                 class="fas fa-circle status-indicator me-2"></i>
              <span>{{(currentMachineStatus$ | async)?.name}}</span>
            </div>
          </div>
        </div>

        <!-- Process Info -->
        <div class="col-md-6 text-center process-info">
          <ng-container *ngIf="terminal.currentProcessName">
            <span class="process-label">Prozess:</span>
            {{ terminal.currentProcessName }}
          </ng-container>
        </div>

        <!-- Actions -->
        <div class="col-md-3">
          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-warning me-3"
                    (click)="openFeedbackModal()"
                    [ngbTooltip]="'Störung melden'"
                    placement="bottom">
              <i class="fas fa-exclamation-triangle"></i>
            </button>
            <div class="date-display">
              {{ currentDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>