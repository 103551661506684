<div class="task-container">
  <div class="status-card">
    <!-- Header -->
    <div class="card-header">
      <h2>{{ currentTask.name }}</h2>
    </div>

    <!-- Content -->
    <div class="card-content">
      <!-- Description -->
      <div class="task-description" *ngIf="currentTask.description">
        <span [innerHTML]="currentTask.description"></span>
      </div>

      <!-- Info Box -->
      <div class="info-box" *ngIf="currentTask.infoText">
        <i class="fas fa-info-circle"></i>
        <span [innerHTML]="currentTask.infoText"></span>
      </div>

      <!-- Status Buttons Grid -->
      <div class="status-grid">
        <!-- Skip Button -->
        <div class="status-item" *ngIf="addSkipButton">
          <button class="status-button skip-button" (click)="submitTask()">
            <span class="button-content">
              <i class="fas fa-arrow-left"></i>
              <span class="button-text">{{'LK.general.skip' | translate}}</span>
            </span>
          </button>
        </div>

        <!-- Status Buttons -->
        <div class="status-item" *ngFor="let state of states$ | async">
          <button class="status-button"
                  (click)="setWorkplaceStatus(state.workplaceStatusId)"
                  [style.--button-color]="state.statusColor"
                  [style.--text-color]="state.fontColor">
            <span class="button-content">
              <span class="button-text">{{state.workplaceStatusName}}</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
