<div class="task-container">
  <!-- Main Content -->
  <div class="content-area">
    <div class="checklist-header">
      <div class="header-icon">
        <img src="assets/img/dyn_checklist_icon.png" alt="Checklist Icon"/>
      </div>
      <h2 class="header-title">{{ currentTask.name }}</h2>
    </div>

    <form [formGroup]="form" class="checklist-form">
      <div class="checklist-items">
        <div class="checklist-item" *ngFor="let checklistItem of checkBoxValues">
          <label class="custom-checkbox">
            <input
                type="checkbox"
                [id]="checklistItem.id"
                [formControl]="checkBoxControls[checklistItem.id]"
                (change)="changeCheckboxValue(checklistItem)"
            />
            <span class="checkbox-mark">
              <i class="fas fa-check"></i>
            </span>
            <span class="checkbox-label">{{ checklistItem?.key | translate }}</span>
          </label>
        </div>
      </div>
    </form>
  </div>

  <!-- Side Panel for Images and Sensors -->
  <div class="media-panel" *ngIf="currentTask.sensors?.length || currentTask.images?.length">
    <div class="images-section" *ngIf="currentTask.images?.length">
      <lib-flux-image [task]="currentTask"></lib-flux-image>
    </div>
    <div class="sensors-section" *ngIf="currentTask.sensors?.length">
      <lib-flux-sensor *ngFor="let sensor of currentTask.sensors"
                       [sensor]="sensor">
      </lib-flux-sensor>
    </div>
  </div>
</div>