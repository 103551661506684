import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ENV } from '../../../../../config/config';

@Injectable({ providedIn: 'root' })
export class OperationService {
    public currentActiveProcess: any;

    constructor(private http: HttpClient, @Inject(ENV) private env) {}

    getOperationsToLogin(workplaceId: string): Observable<any> {
        return this.http.get<any[]>(
            this.env.apiBaseUrl + 'services/fluxordermanagement/api/workplace/' + workplaceId + '/operationsForLogon'
        );
    }

    logonOperation(operationPostingMap): Observable<any> {
        return this.http.post<any>(
            this.env.apiBaseUrl +
                'services/fluxprocess/api/operation/' +
                operationPostingMap.id +
                '/logonToWorkplace/' +
                operationPostingMap.workplaceId,
            operationPostingMap
        );
    }
}
