import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CamundaService } from '../../../service/camunda.service';
import { FluxBPMState } from '../../../store/reducer/flux-bpm.reducers';
import { Task } from '../../../types/task.interface';
import { FinishedTaskAction } from '../../../store/actions/task-state.action';
import { TaskResult } from '../../../types/task-result.interface';
import { ButtonConfig } from '../../../types/button-config.interface';

@Component({
    selector: 'lib-flux-worker-scan-step-template',
    templateUrl: './abstract-template.component.html',
    styleUrls: ['./abstract-template.component.scss'],
    standalone: true
})
export abstract class AbstractTemplateComponent extends OnDestroyMixin {
    currentTask: Task;
    form: UntypedFormGroup;

    @Input() set task(task: Task) {
        this.currentTask = task;
        this.taskUpdated();
    }

    public config: ButtonConfig;

    @Output() initilazing: EventEmitter<ButtonConfig> = new EventEmitter();

    @Input() progressTrigger: Subject<boolean> = new BehaviorSubject(false);

    @Input() nextTrigger: Subject<boolean>;

    @Input() cancelTrigger: Subject<boolean>;

    disableNextButton: Subject<boolean> = new BehaviorSubject(false);

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super();
        this.config = {
            showCancelButton: true,
            showNextButton: true,
            progressTrigger: this.progressTrigger,
            nextButtonTranslationKey: 'LK.general.next',
            disableNextButton: this.disableNextButton
        };
    }

    onInit(initialProgressState: boolean): void {
        this.nextTrigger.pipe(untilComponentDestroyed(this)).subscribe(() => {
            this.progressTrigger.next(true);
            this.nextTriggered();
        });
        this.cancelTrigger.pipe(untilComponentDestroyed(this)).subscribe(() => {
            this.progressTrigger.next(false);
            this.camundaService
                .cancelCurrentTask(this.currentTask.processInstanceId)
                .pipe(untilComponentDestroyed(this))
                .subscribe(() => {});
        });
        this.progressTrigger.next(initialProgressState);
        this.initilazing.emit(this.config);
    }

    public finishTask(name: string, value: string): void {
        const taskResult: TaskResult = { name, value } as TaskResult;
        this.store.dispatch(new FinishedTaskAction(this.currentTask));
        this.camundaService
            .completeTask(taskResult, this.currentTask.processInstanceId)
            .pipe(untilComponentDestroyed(this))
            .subscribe(() => {
                this.progressTrigger.next(true);
            });
    }
    public abstract taskUpdated(): void;

    public abstract nextTriggered(): void;
}
