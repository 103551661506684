<div class="modal-header">
  <h4 class="modal-title" *ngIf="selectedImage">Bild {{currentImageIndex + 1}} von {{imagesToDisplay}}</h4>
  <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="modal-body p-0">
  <div class="fullscreen-container">
    <ng-container *ngIf="selectedImage">
      <ng-container [ngSwitch]="selectedImage.uploadType">
        <img *ngSwitchCase="uploadTypes.IMAGE"
             [src]="sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + selectedImage.file)"
             class="fullscreen-image">
        <img *ngSwitchCase="uploadTypes.IMAGE_URL"
             [src]="selectedImage.url"
             class="fullscreen-image">
        <pdf-viewer *ngSwitchCase="uploadTypes.PDF"
                    [src]="selectedImage.url"
                    [render-text]="true"
                    [original-size]="true"
                    class="fullscreen-pdf">
        </pdf-viewer>
      </ng-container>
    </ng-container>
  </div>
</div>