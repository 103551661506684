import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { TaskResult } from '../../../../types/task-result.interface';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { FinishedTaskAction } from '../../../../store/actions/task-state.action';
import { first } from 'rxjs/operators';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TranslateDirective } from '@ngx-translate/core';
import { ImageComponent } from '../../image-component/image.component';
import { SensorComponent } from '../../sensor-component/sensor.component';

@Component({
    selector: 'lib-flux-scan-step-template',
    templateUrl: './scan-template.component.html',
    styleUrls: ['./scan-template.component.scss'],
    imports: [ReactiveFormsModule, TranslateDirective, ImageComponent, SensorComponent],
    standalone: true
})
export class ScanTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    currentTask: Task;
    form: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {
        if (this.form) {
            this.scanResult.setValue('');
        }
    }

    get scanResult(): UntypedFormControl {
        return this.form.get('scanResult') as UntypedFormControl;
    }

    ngOnInit(): void {
        this.disableNextButton.next(true);

        const validators = [Validators.required];
        this.currentTask.patternMappingVariables.forEach(pattern => {
            validators.push(this.regexValidator(new RegExp(pattern.pattern), { pattern: pattern.failureMessage }));
        });

        this.form = this.formBuilder.group({
            scanResult: ['', validators]
        });

        this.valueChangeListener();
        super.onInit(false);
    }

    ngOnDestroy(): void {}

    regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: UntypedFormControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }

    getErrorKeys(): string[] {
        return Object.keys(this.scanResult.errors);
    }

    nextTriggered(): void {
        if (this.form.valid) {
            this.finishTask('', this.scanResult.value);
        } else {
            this.form.markAllAsTouched();
        }
    }

    private valueChangeListener(): void {
        this.scanResult.valueChanges.pipe(untilComponentDestroyed(this)).subscribe((value: string) => {
            if (this.scanResult.valid) {
                this.disableNextButton.next(false);
            } else {
                this.disableNextButton.next(true);
            }
        });
    }
}
