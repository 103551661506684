import { Action } from '@ngrx/store';
import { Task } from '../../types/task.interface';

export const TaskStateAction = {
    CHANGE_TASK: '[FLUXBPM][TASK] change',
    FINISHED_TASK: '[FLUXBPM] finished task',
    START_PROCESS: '[FLUXBPM] start',
    FINISH_PROCESS: '[FLUXBPM] finished',
    INIT_STATE: '[FLUXBPM] init'
};

export class InitAction implements Action {
    readonly type: string = TaskStateAction.INIT_STATE;

    constructor(readonly terminalId: string) {}
}

export class ChangeCurrentTaskAction implements Action {
    readonly type: string = TaskStateAction.CHANGE_TASK;

    constructor(readonly task: Task) {}
}

export class FinishedTaskAction implements Action {
    readonly type: string = TaskStateAction.FINISHED_TASK;

    constructor(readonly task: Task) {}
}

export class StartNewProcessAction implements Action {
    readonly type: string = TaskStateAction.START_PROCESS;

    constructor(readonly process: string) {}
}

export class ProcessFinishedAction implements Action {
    readonly type: string = TaskStateAction.FINISH_PROCESS;

    constructor() {}
}
