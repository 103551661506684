import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'lib-flux-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss'],
    imports: [NgxChartsModule, ReactiveFormsModule],
    standalone: true
})
export class HelpModalComponent extends OnDestroyMixin implements OnInit {

    feedbackForm: FormGroup;

    constructor(public activeModal: NgbActiveModal,
                private fb: FormBuilder) {
        super();

    }

    ngOnInit(): void {

        this.initFeedbackForm();
    }


    private initFeedbackForm(): void {
        this.feedbackForm = this.fb.group({
            type: ['', Validators.required],
            description: ['', [Validators.required, Validators.minLength(10)]],
            priority: ['medium', Validators.required]
        });
    }



    submitFeedback(): void {
        if (this.feedbackForm.valid) {
            console.log('Feedback:', this.feedbackForm.value);

            this.activeModal.close();

        }
    }

    close(): void {
        this.activeModal.dismiss();
    }
}
