import { Injectable } from '@angular/core';
import { WebSocketAPI } from './websocket-api';
import { Observable, ReplaySubject } from 'rxjs';
import { SocketConfig } from '../types/socket.config';
import { Util } from '@flux/flux-core';

@Injectable({ providedIn: 'root' })
export class SocketStateService {
    webSocketObserverList = [];

    constructor() {}

    initializeWithTopicAndEndpoint(
        socketConfig: SocketConfig,
        topic: string,
        endpoint: string
    ): Observable<WebSocketAPI> {
        if (Util.checkIfNull(this.webSocketObserverList[socketConfig.id])) {
            console.log(topic + 'NullCheck');
            const webSocketAPI = this.createWebSocketAPI(topic, endpoint, socketConfig.sendPath);
            this.connect(webSocketAPI);
            this.webSocketObserverList[socketConfig.id] = new Observable(observer => {
                observer.next(webSocketAPI);
            });
        }
        return this.webSocketObserverList[socketConfig.id];
    }

    initialize(socketConfig: SocketConfig): Observable<WebSocketAPI> {
        if (Util.checkIfNull(this.webSocketObserverList[socketConfig.id])) {
            const webSocketAPI = this.createWebSocketAPI(
                socketConfig.topic,
                socketConfig.endpoint,
                socketConfig.sendPath
            );
            this.connect(webSocketAPI);
            this.webSocketObserverList[socketConfig.id] = new Observable(observer => {
                observer.next(webSocketAPI);
            });
        }
        return this.webSocketObserverList[socketConfig.id];
    }

    private connect(webSocketAPI: WebSocketAPI): void {
        webSocketAPI._connect();
    }

    private createWebSocketAPI(topic: string, endpoint: string, sendpath: string): WebSocketAPI {
        const messageQueue = new ReplaySubject<any>(1);
        const webSocketAPI = new WebSocketAPI(messageQueue);
        webSocketAPI.topic = topic;
        webSocketAPI.webSocketEndPoint = endpoint;
        webSocketAPI.sendPath = sendpath;
        return webSocketAPI;
    }
}
