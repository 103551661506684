<div class="modal-header">
  <h4 class="modal-title">Störung melden</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="feedbackForm">
    <div class="mb-3">
      <label for="issueType" class="form-label">Störungstyp</label>
      <select class="form-select" id="issueType" formControlName="type">
        <option value="">Bitte wählen...</option>
        <option value="machine">Maschinenfehler</option>
        <option value="process">Prozessfehler</option>
        <option value="system">Systemfehler</option>
        <option value="other">Sonstiges</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="description" class="form-label">Beschreibung</label>
      <textarea class="form-control"
                id="description"
                rows="4"
                formControlName="description"
                placeholder="Beschreiben Sie die Störung..."></textarea>
      <div class="form-text" *ngIf="feedbackForm.get('description')?.errors?.['required'] &&
                     feedbackForm.get('description')?.touched">
        Bitte geben Sie eine Beschreibung ein.
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label d-block">Dringlichkeit</label>
      <div class="btn-group"  formControlName="priority">
        <label  class="btn-outline-secondary">
          <input  type="radio" value="low"> Niedrig
        </label>
        <label  class="btn-outline-secondary">
          <input  type="radio" value="medium"> Mittel
        </label>
        <label  class="btn-outline-secondary">
          <input  type="radio" value="high"> Hoch
        </label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-secondary"
          (click)="close()">Abbrechen</button>
  <button type="button"
          class="btn btn-primary"
          [disabled]="!feedbackForm.valid"
          (click)="submitFeedback()">
    Störung melden
  </button>
</div>