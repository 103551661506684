<div class="row card-layout">
  <div class="col card-container">
    <div tabindex="0" class="card" id="basic_card">
      <div class="task-icon"><img src="assets/img/default_bpm_step_icon.png"/></div>
          <div class="card-title">
            <div class="col-6 mx-auto">
              {{ currentTask.name }}
            </div>
      </div>
      <div class="card-body d-flex justify-content-center align-items-center">
        <div class="row w-100">
          <div class="col-10  mx-auto alert alert-info" *ngIf="currentTask.infoText">
            <div class="row">
              <div class="col-1">
                <strong>INFO</strong>
              </div>
              <div class="col-11">
                <span [innerHTML]="currentTask.infoText"></span>
              </div>
            </div>
          </div>
          <div *ngIf="currentTask.description" class="task-description"><span
            [innerHTML]="currentTask.description "></span></div>
          <div class="row">
            <div class="col-2">
              <div class="list-container">
                <div class="list">
                  <ul class="m-0">

                    <ng-container *ngFor="let logonOperation of operations$ | async">
                      <li (click)="onOperationSelect(logonOperation.operation)"
                          [class.selected]="selectedOperation?.id === logonOperation.operation.id">
                        {{ logonOperation.operation.number }}<br/>
                        <span *ngIf="logonOperation.operation.description">{{ logonOperation.operation.description }}
                          <br/></span>
                        <span class="fs-14"> {{ logonOperation.operation.primaryArticleName }} <br/></span>
                        <span class="fs-14"> {{ logonOperation.operation.primaryArticleNumber }} <br/></span>

                        {{ logonOperation.operation.goodPart ? (logonOperation.operation.goodPart | number: '1.2') : (0 | number: '1.2') }}
                        / {{ logonOperation.operation.primaryQuantity | number: '1.2' }} {{ logonOperation.operation.primaryQuantityUnitName | translate }}
                      </li>

                    </ng-container>


                    <ng-template #noOperationsAvailable>
                      <p>{{ 'LK.operations.noOperationAvailable' | translate }}</p>
                    </ng-template>
                  </ul>
                </div>
              </div>

            </div>
            <div class="col-8">
              <ng-container *ngIf="selectedOperation">
                <h1 class="m-5" style="text-align: center">{{ selectedOperation.number }}</h1>
                <lib-flux-operation-detail [operation]="selectedOperation"></lib-flux-operation-detail>
              </ng-container>
              <ng-container *ngIf="!selectedOperation">
                <div class="row empty-state-row">
                  <div class="records-empty-state-container">
                    <p class="fs-16">
                      {{ 'LK.flow.operationList.selectOperation' | translate }}
                    </p>
                    <img src="assets/img/empty-state-no-data.png" alt="empty-state"/>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="selectedOperation" class="col-2">
              <button class="btn btn-primary" (click)="logonOperation()">Logon</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



