import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { TaskResult } from '../../../../types/task-result.interface';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { UserInput } from '../../../../types/user-input.interface';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslateDirective } from '@ngx-translate/core';
import { SensorComponent } from '../../sensor-component/sensor.component';
import { ImageComponent } from '../../image-component/image.component';
import { Util } from '@flux/flux-core';

@Component({
    selector: 'lib-flux-user-input-step-template',
    templateUrl: './user-input-template.component.html',
    styleUrls: ['./user-input-template.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgSwitch,
        NgForOf,
        NgSwitchCase,
        NgIf,
        TranslateDirective,
        SensorComponent,
        ImageComponent
    ],
    standalone: true
})
export class UserInputTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    currentTask: Task;
    form: UntypedFormGroup;

    sensorsExpanded: false;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case '%':
                this.finishTask('Weiter', 'yes');
                break;
            case '§':
                this.finishTask('Zurück', 'back');
                break;
        }
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>,
        private doms: DomSanitizer
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {
        if (this.form) {
            this.inputComponent.setValue('');
        }
    }

    get inputComponent(): UntypedFormControl {
        return this.form.get('inputComponent') as UntypedFormControl;
    }

    safeCss(style): SafeStyle {
        if (Util.checkIfNull(style)){
           return;
        }
        if (style.trim().length > 0) {
            return this.doms.bypassSecurityTrustStyle(style);
        }
    }

    toggleFullscreen(): void{

    }

    toggleSensors(): void{

    }

    ngOnInit(): void {
        const validators = [Validators.required];
        this.currentTask.patternMappingVariables.forEach(pattern => {
            validators.push(this.regexValidator(new RegExp(pattern.pattern), { pattern: pattern.failureMessage }));
        });

        this.form = this.formBuilder.group({
            inputComponent: ['', validators]
        });
        super.onInit(false);
    }

    regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: UntypedFormControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }

    hasSideContent(): boolean {
        return (this.currentTask?.images?.length > 0 || this.currentTask?.sensors?.length > 0);
    }

    ngOnDestroy(): void {}

    getErrorKeys(): string[] {
        return Object.keys(this.inputComponent.errors);
    }

    submitTask(userInput: UserInput): void {
        this.finishTask(userInput.name, userInput.value);
    }

    nextTriggered(): void {
        if (this.form.valid) {
            this.finishTask('', this.inputComponent.value);
        }
    }
}
