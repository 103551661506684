<form (ngSubmit)="nextTriggered()" [formGroup]="form">
  <div class="task-container">
    <!-- Task Content Side -->
    <div class="task-content">
      <div class="content-wrapper">
        <!-- Task Header -->
        <div class="task-header">
          <div class="task-icon">
            <img src="assets/img/user_icon.png" alt="Task Icon" />
          </div>
          <h2 class="task-title">{{ currentTask.name }}</h2>
        </div>

        <!-- Task Body -->
        <div class="task-body">
          <div class="task-description" *ngIf="currentTask.description">
            <span [innerHTML]="currentTask.description"></span>
          </div>

          <div class="info-alert" *ngIf="currentTask.infoText">
            <i class="fas fa-info-circle info-icon"></i>
            <span [innerHTML]="currentTask.infoText"></span>
          </div>

          <!-- User Inputs -->
          <div class="user-inputs">
            <!-- Button Inputs -->
            <div class="button-input-container">
              <ng-container *ngFor="let userInput of currentTask.userInputs">
                <ng-container [ngSwitch]="userInput.inputType">
                  <div *ngSwitchCase="'Button'" class="button-input">
                    <button
                        [style]="safeCss(userInput.style)"
                        class="btn btn-primary option-button"
                        type="button"
                        (click)="submitTask(userInput)">
                      {{ userInput.name }}
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <!-- Text Input -->
            <ng-container *ngFor="let userInput of currentTask.userInputs">
              <ng-container [ngSwitch]="userInput.inputType">
                <div *ngSwitchCase="'Textfield'" class="text-input">
                  <label>{{ userInput.name }}</label>
                  <input
                      class="form-control"
                      autofocus
                      formControlName="inputComponent"
                      type="text"
                      [placeholder]="'Bitte ' + userInput.name + ' eingeben'"
                  />
                  <div class="validation-feedback"
                       *ngIf="inputComponent?.errors &&
                             (inputComponent.dirty || inputComponent.touched)">
                    <ng-container *ngFor="let errorKey of getErrorKeys()">
                      <div class="error-message"
                           *ngIf="inputComponent?.errors[errorKey]"
                           [translate]="'LK.general.validation.text.' + errorKey"
                           [translateParams]="{
                               value: inputComponent?.errors[errorKey][errorKey]
                           }">
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <!-- Compact Sensors (wenn keine Bilder) -->
          <div class="sensors-compact" *ngIf="!currentTask.images?.length && currentTask.sensors?.length">
            <lib-flux-sensor
                *ngFor="let sensor of currentTask.sensors"
                [sensor]="sensor">
            </lib-flux-sensor>
          </div>
        </div>
      </div>
    </div>

    <!-- Media Panel -->
    <div class="media-panel" *ngIf="hasSideContent()">
      <!-- Images Section -->
      <div class="images-section" *ngIf="currentTask.images?.length">
        <div class="images-header">
          <h3>Abbildung</h3>
        </div>
        <lib-flux-image [task]="currentTask"></lib-flux-image>
      </div>

      <!-- Sensors Section -->
      <div class="sensors-section" *ngIf="currentTask.sensors?.length && currentTask.images?.length">
        <div class="sensors-header" (click)="toggleSensors()">
          <h3>Sensordaten</h3>
          <i class="fas" [class.fa-chevron-down]="!sensorsExpanded"
             [class.fa-chevron-up]="sensorsExpanded"></i>
        </div>
        <div class="sensors-content" [class.expanded]="sensorsExpanded">
          <lib-flux-sensor
              *ngFor="let sensor of currentTask.sensors"
              [sensor]="sensor">
          </lib-flux-sensor>
        </div>
      </div>
    </div>
  </div>
</form>