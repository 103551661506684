import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CamundaService } from '../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../store/reducer/flux-bpm.reducers';
import { Observable } from 'rxjs';
import { AbstractTemplateComponent } from '../abstract/abstract-template.component';
import moment from 'moment';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Util } from '@flux/flux-core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, DatePipe, KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ImageComponent } from '../image-component/image.component';
import { interval } from 'rxjs/internal/observable/interval';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
    selector: 'lib-flux-report-template',
    templateUrl: './report-template.component.html',
    styleUrls: ['./report-template.component.scss'],
    imports: [
        NgbNav,
        NgbNavItem,
        TranslatePipe,
        NgbNavContent,
        NgbNavLink,
        AsyncPipe,
        NgIf,
        NgForOf,
        DatePipe,
        KeyValuePipe,
        NgApexchartsModule,
        NgbNavOutlet,
        NgClass,
        ImageComponent
    ],
    standalone: true
})
export class ReportTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;

    variables$: Observable<any>;

    recordedInstances$: Observable<any>;

    logs$: Observable<any>;

    currentTime$ = interval(1000).pipe(
        map(() => moment().date()),
        untilComponentDestroyed(this) // Nutze einen Destroy Subject für Cleanup
    );

    dateFormat = { type: 'dateTime', format: 'dd.MM.yyyy hh:mm' };

    data = [];

    sensorData$: Observable<any>;

    public chartOptions: Partial<ApexOptions>;

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected camundaService: CamundaService,
        protected translateService: TranslateService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    public taskUpdated(): void {
        this.variables$ = this.camundaService.getVariableTask(this.currentTask.processInstanceId);
        this.logs$ = this.camundaService.getLogsOfTask(this.currentTask.processInstanceId);
        this.recordedInstances$ = this.camundaService.getRecordingInstances(this.currentTask.processInstanceId).pipe(
            tap(val => {
                if (val.length > 0) {
                    this.processValues(val[0]);
                }
            })
        );
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({});

        this.chartOptions = {
            series: [],
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    autoSelected: 'zoom'
                },
                fill: {
                    type: 'gradient'
                },
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    format: 'hh:mm:ss'
                }
            },
            stroke: {
                curve: 'straight'
            },
            xaxis: {
                type: 'datetime'
            }
        } as any as ApexOptions;
        super.onInit(false);
    }

    ngOnDestroy(): void {}

    nextTriggered(): void {
        this.finishTask('', null);
    }

    formatVariableValue(value: any, type: string): string {
        if (value === null || value === undefined) {
            return 'null';
        }
        if (type === 'Json') {
            try {
                return JSON.stringify(JSON.parse(value), null, 2);
            } catch {
                return value;
            }
        }
        return value.toString();
    }

    sortAndFilterVariables(variables: any[]): any[] {
        if (!variables) {
            return [];
        }
        return variables.sort(
            (a, b) =>
                new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
        );
    }

    getVariableStatusClass(state: string): string {
        switch (state?.toUpperCase()) {
            case 'CREATED':
                return 'text-bg-success';
            case 'DELETED':
                return 'text-bg-danger';
            default:
                return 'text-bg-secondary';
        }
    }

    getActionIcon(action: string): string {
        if (!action) {
            return 'fa-circle-question';
        } // Default icon

        switch (action.toUpperCase()) {
            case 'FIREWORK':
                return 'fa-rocket';
            case 'REPORT':
                return 'fa-file-lines';
            case 'SCAN':
                return 'fa-barcode';
            case 'ERROR':
                return 'fa-triangle-exclamation';
            case 'QUESTION':
                return 'fa-circle-question';
            case 'CHECKLIST':
                return 'fa-list-check';
            case 'DYN_CHECKLIST':
                return 'fa-list';
            case 'SERVICE_TASK':
                return 'fa-gear';
            case 'SHOW_RUNNING_ORDERS':
                return 'fa-list-ol';
            case 'WORKER_SCAN':
                return 'fa-user-check';
            case 'SHOW_LOGGED_IN_WORKERS':
                return 'fa-users';
            case 'SELECT_WORKPLACE_STATUS':
                return 'fa-building';
            case 'ORDER_SIGN_IN':
                return 'fa-sign-in';
            case 'FORM':
                return 'fa-wpforms';
            default:
                return 'fa-circle-info';
        }
    }

    getActionColor(action: string): string {
        if (!action) {
            return 'text-secondary';
        }
        switch (action.toUpperCase()) {
            case 'ERROR':
                return 'text-danger';
            case 'QUESTION':
                return 'text-warning';
            case 'FIREWORK':
                return 'text-success';
            default:
                return 'text-primary';
        }
    }

    getActivityStatusClass(action: string): string {
        switch (action.toLowerCase()) {
            case 'complete':
                return 'status-completed';
            case 'error':
            case 'terminate':
                return 'status-failed';
            default:
                return '';
        }
    }

    calculateDurationBetween(startTime: string | Date, endTime: string | Date): string {
        const start = moment(startTime);
        const end = moment(endTime);
        const duration = moment.duration(end.diff(start));

        // Gleiche Formatierung wie oben
        if (duration.asHours() >= 1) {
            return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
        } else if (duration.asMinutes() >= 1) {
            return `${Math.floor(duration.asMinutes())}m`;
        } else {
            return `${Math.floor(duration.asSeconds())}s`;
        }
    }

    calculateDuration(startTime: string | Date): string {
        const start = moment(startTime);
        const now = moment();
        const duration = moment.duration(now.diff(start));

        // Format: 2h 15m oder 45m oder 30s
        if (duration.asHours() >= 1) {
            return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
        } else if (duration.asMinutes() >= 1) {
            return `${Math.floor(duration.asMinutes())}m`;
        } else {
            return `${Math.floor(duration.asSeconds())}s`;
        }
    }

    getNow(): any{
        return moment();
    }

    getDurationBetweenSteps(currentDate: string, nextDate: string): string {
        const current = new Date(currentDate).getTime();
        const next = new Date(nextDate).getTime();
        const durationMs = next - current;

        // Negative Zeiten abfangen
        if (durationMs < 0) {
            return '';
        }

        // Zeitintervalle in Millisekunden
        const intervals = {
            day: 86400000,
            hour: 3600000,
            minute: 60000,
            second: 1000,
        };

        if (durationMs >= intervals.day) {
            const days = Math.floor(durationMs / intervals.day);
            const hours = Math.floor((durationMs % intervals.day) / intervals.hour);
            return `${days}d ${hours}h`;
        } else if (durationMs >= intervals.hour) {
            const hours = Math.floor(durationMs / intervals.hour);
            const minutes = Math.floor(
                (durationMs % intervals.hour) / intervals.minute
            );
            return `${hours}h ${minutes}m`;
        } else if (durationMs >= intervals.minute) {
            const minutes = Math.floor(durationMs / intervals.minute);
            const seconds = Math.floor(
                (durationMs % intervals.minute) / intervals.second
            );
            return `${minutes}m ${seconds}s`;
        } else {
            const seconds = Math.floor(durationMs / intervals.second);
            return `${seconds}s`;
        }
    }

    getStatusClass(state: string): string {
        switch (state.toLowerCase()) {
            case 'completed':
                return 'status-completed';
            case 'failed':
            case 'terminated':
                return 'status-failed';
            case 'active':
                return 'status-running';
            default:
                return '';
        }
    }

    toDateFormatted(date: string): string {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }

    convertGroupToSeries(listOfDataPoints: any): any {
        const series = [];
        listOfDataPoints.map(dataPoint => {
            if (Util.checkIfNull(series[dataPoint.name])) {
                series[dataPoint.name] = [{ name: dataPoint.name, data: [] }];
            }
            series[dataPoint.name][0].data.push({ x: dataPoint.eventTs, y: dataPoint.value });
        });
        return series;
    }

    getTranslationOfValue(sensorName: string, value: string): string {
        const tranlationKey = 'LK.translate.sensorValue.' + sensorName + '.' + value;
        const translation = this.translateService.instant(tranlationKey);
        if (translation === tranlationKey) {
            return value;
        }
        return translation;
    }

    processValues(values: any): any {
        this.sensorData$ = this.camundaService.loadSensorDataOfRecordedInstance(values).pipe(
            map(events => events.sort((a, b) => new Date(b.sourceTime).getTime() - new Date(a.sourceTime).getTime())),
            map(events =>
                events.map(ev => ({ name: ev.measurePointNumber, value: ev.numericValue, eventTs: ev.sourceTime }))
            ),
            map(events => this.convertGroupToSeries(events))
        );
    }

    protected readonly moment = moment;
}
