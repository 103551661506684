import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadType } from '../types/enums/upload-type';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'lib-flux-image-fullscreen',
    templateUrl: './image-viewer-modal.component.html',
    styleUrls: ['./image-viewer-modal.component.scss'],
    imports: [NgxChartsModule, ReactiveFormsModule, PdfViewerModule],
    standalone: true
})
export class ImageViewerModalComponent extends OnDestroyMixin implements OnInit {


    @Input() imagesToDisplay = 0;

    @Input() currentImageIndex = 0;
    @Input() selectedImage: any = null;

    uploadTypes = UploadType;

    constructor(public activeModal: NgbActiveModal,
                public sanitizer: DomSanitizer) {
        super();

    }

    ngOnInit(): void {


    }




    close(): void {
        this.activeModal.dismiss();
    }
}
