import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StopwatchService } from './services/stopwatch.service';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState, selectCurrentSelectedTask } from '../../../../store/reducer/flux-bpm.reducers';
import { BehaviorSubject, Observable, Subject, Subscription, takeLast, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Task } from '../../../../types/task.interface';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-stopwatch-template',
    templateUrl: './stopwatch-template.component.html',
    styleUrls: ['./stopwatch-template.component.scss'],
    imports: [NgIf, AsyncPipe, DatePipe],
    standalone: true
})
export class StopwatchTemplateComponent extends AbstractTemplateComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    currentTask$: Observable<Task>;

    @Input() resetCountdown;

    private subscriptions = new Subscription();

    constructor(
        public stopwatchService: StopwatchService,
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    ngOnInit(): void {
        this.currentTask$ = this.store.select(selectCurrentSelectedTask).pipe(filter(task => !!task));

        this.currentTask$.subscribe(task => {
            if (task.variables.startStopwatch) {
                this.stopwatchService.start();
            } else {
                this.stopwatchService.stop();
            }

            if (task.variables.startCountdown) {
                this.subscriptions.add(
                    this.stopwatchService.countdownOver$.pipe(takeUntil(this.destroy$)).subscribe(over => {
                        if (over) {
                            this.stopwatchService.countdownOver$.next(false);
                            this.destroy$.next();
                            this.destroy$.complete();
                            this.subscriptions.unsubscribe();
                            if (task.variables.startCountdown) {
                                this.finishTask('', null);
                            }
                        }
                    })
                );
                this.stopwatchService.startCountdown();
            } else {
                this.stopwatchService.countdownOver$.next(false);
                if (this.subscriptions) {
                    this.subscriptions.unsubscribe();
                    this.destroy$.next();
                    this.destroy$.complete();
                }
            }

            if (this.resetCountdown !== undefined && this.resetCountdown !== null && this.resetCountdown) {
                this.subscriptions.unsubscribe();
                this.stopwatchService.countdownOver$.next(false);
            }
        });
    }
    ngOnDestroy(): void {}
    nextTriggered(): void {}

    taskUpdated(): void {}
}
