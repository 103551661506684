<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Chartdetail</h4>
  <button (click)="close()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" #modalBody>

  <ngx-charts-line-chart
    [view]="view"
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="printData"
    [autoScale]="true"
  >
  </ngx-charts-line-chart>

</div>
