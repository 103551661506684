<div class="operation-detail">
    <div>
        <p class="label">{{ 'LK.operation.articleNo' | translate }}</p>
        <p class="value">{{ operation.primaryArticleNumber || '-' }}</p>
    </div>
    <div>
        <p class="label">{{ 'LK.operation.articleName' | translate }}</p>
        <p class="value">{{ operation.primaryArticleName || '-' }}</p>
    </div>
    <div>
        <p class="sub-header">{{ 'LK.general.quantity' | translate }}</p>
    </div>
    <div>
        <p class="label sub-item">{{ 'LK.operation.actualQuantity' | translate }}</p>
        <p class="value">{{ operation.actualQuantity || 0 | number:'1.0-2'}} {{ operation.primaryQuantityUnitName }}</p>
    </div>
    <div class="quantity-list">
        <p class="label">{{ 'LK.operation.goodPart' | translate }}</p>
        <p class="value">{{ operation.goodPart || 0  | number:'1.0-2'  }} {{ operation.primaryQuantityUnitName | translate }}</p>
    </div>
    <div class="quantity-list">
        <p class="label">{{ 'LK.operation.scrap' | translate }}</p>
        <p class="value">{{ operation.scrap || 0  | number:'1.0-2'}} {{ operation.primaryQuantityUnitName | translate }}</p>
    </div>
    <div class="quantity-list">
        <p class="label">{{ 'LK.operation.rework' | translate }}</p>
        <p class="value">{{ operation.rework || 0 | number:'1.0-2' }} {{ operation.primaryQuantityUnitName | translate }}</p>
    </div>
    <div>
        <p class="label sub-item">{{ 'LK.operation.plannedQuantity' | translate }}</p>
        <p class="value">{{ operation.primaryQuantity || 0 | number:'1.0-2'}} {{ operation.primaryQuantityUnitName | translate }}</p>
    </div>
    <div>
        <p class="label sub-item">{{ 'LK.operation.openQuantity' | translate }}</p>
        <p class="value">{{ operation.openQuantity || 0 | number:'1.0-2'}} {{ operation.primaryQuantityUnitName | translate }}</p>
    </div>
    <div>
        <p class="sub-header">{{ 'LK.operation.workDuration' | translate }}</p>
    </div>
    <div>
        <p class="label sub-item">{{ 'LK.operation.plannedDuration' | translate }}</p>
        <p class="value">{{ operation.plannedDuration || '-' }}</p>
    </div>
    <div>
        <p class="label sub-item">{{ 'LK.operation.currentDuration' | translate }}</p>
        <p class="value">{{ operation.workDuration || '-' }}</p>
    </div>
</div>
