<div class="loading-container">
  <div class="loading-content">
    <!-- Main Loading Animation -->

    <!-- Loading Text -->
    <div class="loading-text">
      <h2>Nächster Schritt wird vorbereitet</h2>
      <div class="typing-animation">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <!-- Cancel Button -->
    <button class="cancel-button" (click)="cancelProcess()">
      <span class="button-content">
        <i class="fas fa-times"></i>
        <span>Vorgang abbrechen</span>
      </span>
      <div class="button-background"></div>
    </button>
  </div>
</div>