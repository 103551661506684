import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
// @ts-ignore
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ButtonConfig } from '../../../../types/button-config.interface';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

// @ts-ignore

@Component({
    selector: 'lib-flux-screen-footer-modal',
    templateUrl: './step-footer-template.component.html',
    styleUrls: ['./step-footer-template.component.scss'],
    imports: [NgIf, TranslatePipe],
    standalone: true
})
export class StepFooterTemplateComponent extends OnDestroyMixin implements OnInit, OnDestroy {
    constructor(private translate: TranslateService) {
        super();
    }
    @Output() triggerNext: EventEmitter<{}> = new EventEmitter();

    @Output() triggerCancel: EventEmitter<{}> = new EventEmitter();
    @Output() triggerNo: EventEmitter<{}> = new EventEmitter();

    @Input() configChangeListener: Subject<ButtonConfig>;

    showNext = true;

    nextButtonTranslationKey = this.translate.instant('LK.general.next');

    showCancel = true;
    disableNextButton = false;

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
        if (event.key === ']') {
            if (this.showNext) {
                this.submitTask();
            }
        }
    }

    ngOnInit(): void {
        this.configChangeListener.pipe(untilComponentDestroyed(this)).subscribe((config: ButtonConfig) => {
            this.showNext = config.showNextButton;
            this.showCancel = config.showCancelButton;
            this.nextButtonTranslationKey = this.translate.instant(config.nextButtonTranslationKey);
            config.disableNextButton.pipe(untilComponentDestroyed(this)).subscribe((val: boolean) => {
                this.disableNextButton = val;
                console.log('disableNextButton', val);
            });
        });
    }
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    cancelTask(): void {
        this.triggerCancel.emit(true);
    }

    submitTask(): void {
        this.triggerNext.emit(true);
    }
}
