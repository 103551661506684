import { Component, Inject, Input, OnInit } from '@angular/core';
import { Task } from '../../../types/task.interface';

import { SocketConfig } from '../../../types/socket.config';
import { SocketStateService } from '../../../websocket/socket-state.service';
import { SensorResponse } from '../../../types/sensor-response';
import { Util } from '@flux/flux-core';
import { Sensors } from '../../../types/sensors';
import { ENV } from '../../../config/config';
import { WebSocketAPI } from '../../../websocket/websocket-api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDetailModalComponent } from './chart-detail-modal/chart-detail-modal.component';
import moment from 'moment';
import { LineChartModule } from '@swimlane/ngx-charts';
import { DecimalPipe, NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-sensor',
    templateUrl: './sensor.component.html',
    styleUrls: ['./sensor.component.scss'],
    imports: [LineChartModule, DecimalPipe, NgIf],
    standalone: true
})
export class SensorComponent extends OnDestroyMixin implements OnInit {
    @Input() sensor: Sensors;

    currentTask: Task;

    sensorResult = 0.0;

    socketConfig: SocketConfig;

    webSocketConnection$: Observable<WebSocketAPI>;

    modalRef;

    initilazing = true;

    realData: any[] = [];
    realDataObs: BehaviorSubject<any> = new BehaviorSubject<any>({});

    view: any[] = [700, 300];

    // options
    legend = false;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = true;
    showXAxisLabel = true;
    xAxisLabel = '';
    yAxisLabel = '';
    timeline = true;

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };

    constructor(
        public socketStateService: SocketStateService,
        @Inject(ENV) private env,
        private modalService: NgbModal
    ) {
        super();
        this.realData.push({
            name: '',
            series: []
        });
    }

    @Input() set task(task: Task) {
        this.currentTask = task;
    }

    showDetails(): void {
        this.modalRef = this.modalService.open(ChartDetailModalComponent, { size: 'xl', backdrop: false });

        this.modalRef.componentInstance.name = this.sensor.name;
        this.modalRef.componentInstance.realData = this.realDataObs;
        this.modalRef.result.then(
            () => {
                this.modalRef = null;
            },
            reason => {}
        );
    }

    ngOnInit(): void {
        this.socketConfig = {
            id: 'Sensor' + this.sensor.sensorId,
            endpoint: this.env.connectorSocketUrl,
            topic: '/topic/measuredata/measuringpoint/',
            sendPath: ''
        } as any as SocketConfig;
        this.webSocketConnection$ = this.socketStateService.initializeWithTopicAndEndpoint(
            this.socketConfig,
            this.socketConfig.topic + this.sensor.sensorId,
            this.socketConfig.endpoint
        );

        this.webSocketConnection$.pipe(untilComponentDestroyed(this)).subscribe((webSocket: WebSocketAPI) => {
            webSocket.messageQueue.pipe(untilComponentDestroyed(this)).subscribe(response => {
                if (!Util.checkIfNull(response)) {
                    const sensorData: SensorResponse = JSON.parse(response);
                    this.yAxisLabel = this.sensor.name;

                    this.sensorResult = parseFloat(sensorData.value as string);

                    this.realData[0].series.push({
                        name: moment(sensorData.sourceTime).format('HH:mm:ss'),
                        value: parseFloat(sensorData.value as string)
                    });

                    this.realData = [...this.realData];
                    this.realDataObs.next(this.realData);
                    // if (this.modalRef?.componentInstance) {
                    //     this.modalRef.componentInstance.liveData = this.liveData;
                    // }
                    this.initilazing = false;
                }
            });
        });
    }

    // public loaded(args: ILoadedEventArgs): void {
    //     this.chart = args.chart;
    // }
}
