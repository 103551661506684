import { Action } from '@ngrx/store';

import {
  ChangeCurrentTaskAction,
  FinishedTaskAction,
  InitAction,
  StartNewProcessAction,
  TaskStateAction
} from '../actions/task-state.action';
import { Task } from '../../types/task.interface';

export interface TaskState {
    currentActiveTask: Task;
    currentProcessName: string;
    terminalId: number;
    isFinished: false;
}

export function currentTaskReducers(state: TaskState, action: Action): any {
    switch (action.type) {
        case TaskStateAction.INIT_STATE:
            const initAction = action as InitAction;
            return {
                ...state,
                terminalId: initAction.terminalId,
                isFinished: false
            };
        case TaskStateAction.CHANGE_TASK:
            const changeTaskAction = action as ChangeCurrentTaskAction;
            return {
                ...state,
                currentActiveTask: changeTaskAction.task,
                isFinished: false
            };
        case TaskStateAction.FINISHED_TASK:
            const finishedTaskAction = action as FinishedTaskAction;
            return {
                ...state,
                isFinished: true
            };
        case TaskStateAction.START_PROCESS:
            const startProcessAction = action as StartNewProcessAction;
            return {
                ...state,
                currentProcessName: startProcessAction.process,
                isFinished: false
            };
        case TaskStateAction.FINISH_PROCESS:
            return {
                ...state,
                currentProcessName: null,
                currentActiveTask: null,
                isFinished: false
            };
        default:
            return state;
    }
}
