import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CamundaService } from '../../../service/camunda.service';
import { Task } from '../../../types/task.interface';
import { Observable, Subscription } from 'rxjs';
import { filter, sequenceEqual, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    FluxBPMState,
    selectCurrentProcessName,
    selectCurrentSelectedTask
} from '../../../store/reducer/flux-bpm.reducers';
import { ChangeCurrentTaskAction, InitAction, ProcessFinishedAction } from '../../../store/actions/task-state.action';
import { SocketStateService } from '../../../websocket/socket-state.service';
import { ProcessStatus } from '../../../types/process-status.interface';
import { ActivatedRoute } from '@angular/router';
import { ENV } from '../../../config/config';
import { Util } from '@flux/flux-core';
import { SocketConfig } from '../../../types/socket.config';
import { WebSocketAPI } from '../../../websocket/websocket-api';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FluxViewerHeaderComponent } from '../viewer-header/flux-viewer-header.component';
import { CurrentStepComponent } from '../../viewer-content/current-step.component';
import { WaitingViewComponent } from '../../viewer-content/waiting-view/waiting-view.component';
import { environment } from '../../../../environments/environment';
import { NgIf } from '@angular/common';

@Component({
    selector: 'lib-flux-flow-viewer',
    templateUrl: './flux-flow-viewer.component.html',
    styleUrls: ['./flux-flow-viewer.component.scss'],
    imports: [FluxViewerHeaderComponent, CurrentStepComponent, WaitingViewComponent, NgIf],
    standalone: true
})
export class FluxFlowViewerComponent extends OnDestroyMixin implements OnInit, OnDestroy {
    isInInitialState = true;

    isReportView = false;

    error = null;
    private terminalId;
    subscriptions: Subscription = new Subscription();

    webSocketApi: WebSocketAPI;

    webSocketConnection$: Observable<WebSocketAPI>;

    constructor(
        private translation: TranslateService,
        private camundaService: CamundaService,
        private store: Store<FluxBPMState>,
        private socketStateService: SocketStateService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.terminalId = this.route.params['value'].terminalId;
        this.store.dispatch(new InitAction(this.terminalId));
        this.subscriptions.add(
            this.store
                .select(selectCurrentProcessName)
                .pipe(
                    untilComponentDestroyed(this),
                    filter(processName => !!processName)
                )
                .subscribe(processName => {
                    this.startProcess(processName);
                })
        );
        this.checkForCurrentProcess();
        this.connectToWebSocket();
        this.webSocketConnection$.pipe(untilComponentDestroyed(this)).subscribe((webSocket: WebSocketAPI) => {
            this.webSocketApi = webSocket;
            webSocket.messageQueue.pipe(untilComponentDestroyed(this)).subscribe(message => {
                const processStatus: ProcessStatus = JSON.parse(message);
                if (
                    this.camundaService.currentActiveProcess?.processInstanceId ===
                        processStatus.payload.processInstanceId &&
                    (processStatus.payload.eventType === 'create' ||
                        processStatus.payload.eventType === 'end' ||
                        processStatus.payload.taskType === 'SERVICE_TASK')
                ) {
                    if (processStatus.processEventType === 'TASK_CHANGED') {
                        this.isInInitialState = false;
                        this.store.dispatch(new ChangeCurrentTaskAction(processStatus.payload));
                        if (processStatus.payload.taskType !== 'REPORT') {
                            this.isReportView = false;
                        } else {
                            this.isReportView = true;
                        }
                    } else if (processStatus.processEventType === 'END') {
                        this.store.dispatch(new ProcessFinishedAction());
                        this.isInInitialState = true;
                    } else if (processStatus.processEventType === 'CANCEL') {
                        this.store.dispatch(new ProcessFinishedAction());
                        this.isInInitialState = true;
                    }
                }
            });
        });

        this.subscriptions.add(
            this.store
                .select(selectCurrentSelectedTask)
                .pipe(untilComponentDestroyed(this))
                .subscribe(task => {
                    if (Util.checkIfNull(task)) {
                        this.isInInitialState = true;
                    } else {
                        this.camundaService.currentActiveTask = task;
                    }
                })
        );
    }

    connectToWebSocket(): void {
        const socketConfig = {
            id: 'task',
            endpoint: environment.viewerSocketUrl,
            topic: '/topic/process/events',
            sendPath: ''
        } as any as SocketConfig;
        this.webSocketConnection$ = this.socketStateService.initializeWithTopicAndEndpoint(
            socketConfig,
            '/topic/process/events',
            environment.viewerSocketUrl
        );
    }

    checkForCurrentProcess(): void {
        this.subscriptions.add(
            this.camundaService
                .getProcessInstance(this.terminalId)
                .pipe(untilComponentDestroyed(this))
                .subscribe(
                    (process: any) => {
                        if (!process.processInstanceId) {
                            // this.startInitialProcess();
                            this.isInInitialState = true;
                        } else if (process.processInstanceId) {
                            this.getTasks();
                            this.isInInitialState = false;
                        }
                    },
                    err => {
                        this.error = err;
                        console.log(err);
                    }
                )
        );
    }

    getTasks(): void {
        this.subscriptions.add(
            this.camundaService
                .getTask(this.camundaService.currentActiveProcess.processInstanceId)
                .pipe(untilComponentDestroyed(this))
                .subscribe(task => {
                    if (task.taskType === 'REPORT') {
                        this.isReportView = true;
                    }
                    this.store.dispatch(new ChangeCurrentTaskAction(task));
                })
        );
    }

    ngOnDestroy(): void {
        this.webSocketApi._disconnect();
        this.subscriptions.unsubscribe();
    }

    checkIfSomethingChanged(newTask$: Observable<Task>): Observable<boolean> {
        return newTask$.pipe(sequenceEqual(newTask$));
    }

    startProcess(name: string): void {
        this.subscriptions.add(
            this.camundaService
                .startProcess(name, this.terminalId)
                .pipe(untilComponentDestroyed(this))
                .subscribe(result => {
                    this.checkForCurrentProcess();
                })
        );
    }
}
