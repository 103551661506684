import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task } from '../../../../types/task.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CamundaService } from '../../../../service/camunda.service';
import { Store } from '@ngrx/store';
import { FluxBPMState } from '../../../../store/reducer/flux-bpm.reducers';
import { Observable } from 'rxjs';
import { map, take, tap, window } from 'rxjs/operators';
import { Util } from '@flux/flux-core';
import { ChartDetailModalComponent } from '../../sensor-component/chart-detail-modal/chart-detail-modal.component';
import { NgbModal, NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import moment from 'moment/moment';
import { AbstractTemplateComponent } from '../../abstract/abstract-template.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { ReportTemplateComponent } from '../../report/report-template.component';

@Component({
    selector: 'lib-flux-error-step-template',
    templateUrl: './error-template.component.html',
    styleUrls: ['./error-template.component.scss'],
  imports: [
    NgIf,
    TranslatePipe,
    NgApexchartsModule,
    ReportTemplateComponent
  ],
    standalone: true
})
export class ErrorTemplateComponent extends AbstractTemplateComponent implements OnInit {
    showDetails = false;
    errorText: string | null = null;

    constructor(
        protected camundaService: CamundaService,
        protected store: Store<FluxBPMState>
    ) {
        super(camundaService, store);
    }

    ngOnInit(): void {
        super.onInit(true);
        this.initializeErrorMessage();
    }

    retry(): void {
       //  window.location.reload();
    }

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

    private initializeErrorMessage(): void {
      // TODO Error Message extrahieren
    }

  nextTriggered(): void {
    this.finishTask('', null);
  }

  public taskUpdated(): void {}
}
