<lib-flux-flow-viewer-header></lib-flux-flow-viewer-header>
<div class="row viewerContent">
    <div class="col">
        <ng-container *ngIf="!isInInitialState">
            <lib-flux-current-step></lib-flux-current-step>
        </ng-container>
        <ng-container *ngIf="isInInitialState">
            <lib-flux-waiting-view></lib-flux-waiting-view>
        </ng-container>

    </div>

   <!-- <div *ngIf="!isInInitialState && !isReportView " class="col-md-4 col-4">
        <lib-flux-process-list></lib-flux-process-list>
    </div>-->

</div>
<div class="col" *ngIf="error">
    <div class="alert alert-danger">
        <div>
            {{error.error.detail}}
        </div>
        <div>
            {{error.error.path}}
        </div>
    </div>
</div>

