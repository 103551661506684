<div class="step-container">
  <ng-container *ngIf="currentTask$ | async as task">
    <!-- Loading State -->
    <ng-container *ngIf="taskPending$ | async">
      <lib-flux-loading-step-template
          [task]="task"
          (initilazing)="componentInitializes($event)"
          [cancelTrigger]="cancelTrigger"
          [nextTrigger]="nextTrigger"
      ></lib-flux-loading-step-template>
    </ng-container>

    <!-- Main Content -->
    <ng-container *ngIf="!(taskPending$ | async)">
      <!-- Stopwatch -->
      <lib-flux-stopwatch-template [task]="task"></lib-flux-stopwatch-template>

      <!-- Content Area -->
      <div class="content-area">

        <ng-container [ngSwitch]="task.taskType">
            <div *ngSwitchCase="'REPORT'" class="row task-view">
                <lib-flux-report-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-report-template>
            </div>
            <div *ngSwitchCase="'SCAN'" class="row task-view">
                <lib-flux-scan-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-scan-step-template>
            </div>
            <div *ngSwitchCase="'ERROR'" class="row task-view">
                <lib-flux-error-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-error-step-template>
            </div>
            <div *ngSwitchCase="'QUESTION'" class="row task-view">
                <lib-flux-user-input-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-user-input-step-template>
            </div>
            <div *ngSwitchCase="'CHECKLIST'" class="row task-view">
                <lib-flux-checklist-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-checklist-step-template>
            </div>
            <div *ngSwitchCase="'DYN_CHECKLIST'" class="row task-view">
                <lib-flux-dyn-checklist-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-dyn-checklist-step-template>
            </div>
            <div *ngSwitchCase="'SERVICE_TASK'" class="row task-view">
                <lib-flux-background-task-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-background-task-step-template>
            </div>
            <div *ngSwitchCase="'SHOW_RUNNING_ORDERS'" class="row task-view">
                <lib-flux-running-operations-step-template
                    [task]="task"
                    (overrideConfigChange)="overrideConfigChangeListener($event)"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-running-operations-step-template>
            </div>
            <div *ngSwitchCase="'WORKER_SCAN'" class="row task-view">
                <lib-flux-worker-scan-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-worker-scan-step-template>
            </div>
            <div *ngSwitchCase="'SHOW_LOGGED_IN_WORKERS'" class="row task-view">
                <lib-flux-worker-logged-in-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-worker-logged-in-template>
            </div>
            <div *ngSwitchCase="'SELECT_WORKPLACE_STATUS'" class="row task-view">
                <lib-flux-workplace-status-step-template
                    style="width: 100%"
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                ></lib-flux-workplace-status-step-template>
            </div>
            <div *ngSwitchCase="'ORDER_SIGN_IN'" class="row task-view">
                <lib-flux-operation-sign-in
                    style="width: 100%"
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                ></lib-flux-operation-sign-in>
            </div>
            <div *ngSwitchCase="'FORM'">
                <lib-flux-form-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                ></lib-flux-form-step-template>
            </div>
            <div *ngSwitchDefault class="row task-view">
                <lib-flux-default-step-template
                    [task]="task"
                    (initilazing)="componentInitializes($event)"
                    [cancelTrigger]="cancelTrigger"
                    [nextTrigger]="nextTrigger"
                    class="col"
                ></lib-flux-default-step-template>
            </div>
        </ng-container>
      </div>
        <div class="footer-wrapper" [class.hidden]="isFooterHidden">
          <lib-flux-screen-footer-modal
              [configChangeListener]="configChangeListener"
              (triggerCancel)="triggerCancel($event)"
              (triggerNext)="triggerNext($event)"
          ></lib-flux-screen-footer-modal>
        </div>
    </ng-container>
  </ng-container>
</div>